import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../../styled/CommonComponents/NormalDrawer";

const useStyles = makeStyles((theme) => ({
    bodyCont: {
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    }
}));

const NetCertifitePayableDetails = ({ openNetCertifitePayableDetails, setOpenNetCertifitePayableDetails }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    return (
        <NormalDrawer
            openDrawer={openNetCertifitePayableDetails}
            setOpenDrawer={setOpenNetCertifitePayableDetails}
            anchor={"right"}
            title={"Net Certifite Payable Details"}
            content={
                <div className={classes.bodyCont} >

                </div>
            }
        />
    );
};

export default NetCertifitePayableDetails;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cloneBudgetCostSubhead } from "./api.call";
import { useDebounce } from 'react-use';
import { IconButton, Button, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import "./index.css"

const useStyles = makeStyles((theme) => ({
    mainCont: {
        maxHeight: "60vh",
        width: "500px",
        overflowY: "auto"
    },
    amountUnitCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "15px"
    },
    subhedsCont: {
        width: "100%",
        maxHeight: "400px",
        overflowY: "auto",
        margin: "10px 0px 10px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
    },
    tableCont: {
        width: "100%",
        border: "1px solid gray",
        borderCollapse: "collapse",
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px"
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
            textAlign: "right",
            paddingRight: "10px"
        },
        "& .PrivateSwitchBase-root-82": {
            padding: "0px"
        },
        "& .MuiIconButton-root": {
            padding: "0px"
        }
    },
    checkBoxCont: {
        width: "45px"
    },
    workitemCont: {
        width: "75px"
    },
    checkBoxTextCont: {
        display: "flex",
        alignItems: "center",
        "& p": {
            fontSize: "14px",
            fontWeight: "400"
        }
    },
    subheadTitle: {
        fontSize: "15px",
        fontWeight: "500",
        margin: "20px 0px 5px"
    },
    cloneText: {
        fontSize: "15px",
        fontWeight: "400",
        marginTop: "0px 0px 15px",
        "& span": {
            fontWeight: "600"
        }
    },
    bottomBtnCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        },
    }
}));

const CloneSubhead = ({
    copyDialogOpen, setcopyDialogOpen, selectedSubheadIds,
    costHeads, getBudgetCostPopulatedData, cancelSelect
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [seletableCostHeads, setSeletableCostHeads] = useState([]);
    const [selectedSubheads, setSelectedSubheads] = useState([]);
    const [isWithQty, setIsWithQty] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let allSubheads = [];
        let locSelectedSubheads = []
        let locSeletableCostHeads = [];
        costHeads.map((costHead) => {
            let items = costHead?.items || []
            allSubheads = [...allSubheads, ...items]
            locSeletableCostHeads.push({
                ...costHead,
                selected: false
            })
        })
        allSubheads.map((subhead) => {
            if (selectedSubheadIds.includes(subhead?._id)) {
                locSelectedSubheads.push({
                    ...subhead,
                    selected: true
                })
            }
        })

        setSelectedSubheads(locSelectedSubheads)
        setSeletableCostHeads(locSeletableCostHeads)
    }, [selectedSubheadIds])

    const submitCopy = async (e) => {
        e.preventDefault();
        setLoading(true)
        let locSelectedBudgetHead = []
        let locAllSelectedSubheads = []
        seletableCostHeads.map((seletableCostHead) => {
            if (seletableCostHead.selected) {
                locSelectedBudgetHead.push(seletableCostHead?._id)
            }
        })
        selectedSubheads.map((selectedSubhead) => {
            if (selectedSubhead.selected) {
                locAllSelectedSubheads.push(selectedSubhead?._id)
            }
        })

        const reqObj = {
            selectedBudgetHead: locSelectedBudgetHead,
            allSelectedSubheads: locAllSelectedSubheads,
            isWithQty,
            userId: user?._id
        }



        await cloneBudgetCostSubhead(reqObj)
            .then(async (data) => {
                await getBudgetCostPopulatedData(false)
                cancelSelect()
                setLoading(false)
                setcopyDialogOpen(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <NormalDialog
            openDialog={copyDialogOpen}
            loading={loading}
            handleCloseShare={() => { setcopyDialogOpen(false) }}
            pageTitle={"Clone Subhead Head(s)"}
            content={<form onSubmit={submitCopy} >
                <div className={classes.mainCont} >
                    <p className={classes.subheadTitle} >Select Head(s)</p>
                    <div className={classes.subhedsCont} >
                        <table className={classes.tableCont} >
                            <thead>
                                <tr style={{ height: "35px" }} >
                                    <th className={classes.checkBoxCont} ></th>
                                    <th>Name</th>
                                    <th className={classes.workitemCont} >Subheads</th>
                                </tr>
                            </thead>
                            <tbody>
                                {seletableCostHeads && seletableCostHeads.map((head, i) => (
                                    <tr key={i} style={{ height: "30px" }} >
                                        <td>
                                            <Checkbox
                                                color="primary"
                                                checked={head?.selected}
                                                onChange={(e) => {
                                                    let arr = [...seletableCostHeads]
                                                    arr[i].selected = e.target.checked
                                                    setSeletableCostHeads(arr)
                                                }}
                                            />
                                        </td>
                                        <td style={{ textAlign: "left", paddingLeft: "5px" }} >{head?.name}</td>
                                        <td style={{ textAlign: "right" }} >{head?.items ? head?.items?.length || 0 : 0}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <p className={classes.subheadTitle} >Selected Subheads</p>
                    <div className={classes.subhedsCont} >
                        <table className={classes.tableCont} >
                            <thead>
                                <tr style={{ height: "35px" }} >
                                    <th className={classes.checkBoxCont} ></th>
                                    <th>Name</th>
                                    <th className={classes.workitemCont} >Workitems</th>
                                    <th>Total BudgetCost</th>
                                    <th>Total Estimate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedSubheads && selectedSubheads.map((subhead, i) => (
                                    <tr key={i} style={{ height: "30px" }} >
                                        <td>
                                            <Checkbox
                                                color="primary"
                                                checked={subhead?.selected}
                                                onChange={(e) => {
                                                    let arr = [...selectedSubheads]
                                                    arr[i].selected = e.target.checked
                                                    setSelectedSubheads(arr)
                                                }}
                                            />
                                        </td>
                                        <td style={{ textAlign: "left", paddingLeft: "5px" }} >{subhead?.name}</td>
                                        <td style={{ textAlign: "right" }} >{subhead?.items ? subhead?.items?.length || 0 : 0}</td>
                                        <td style={{ textAlign: "right" }} >{parseFloat(subhead?.totalBudgetCost || 0).toFixed(2)}</td>
                                        <td style={{ textAlign: "right" }} >{(parseFloat(subhead?.totalEstimatedCost || 0) + parseFloat(subhead?.totalEstimatedGST || 0)).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.checkBoxTextCont} >
                        <Checkbox
                            color="primary"
                            checked={isWithQty}
                            onChange={(e) => {
                                setIsWithQty(e.target.checked)
                            }}
                        />
                        <p>Clone along with quantity take off</p>
                    </div>
                </div>
                <div>
                    {loading && (<LinearProgress />)}
                </div>
                <div className={classes.bottomBtnCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >Copy</Button>
                </div>
            </form>}
        />
    );
};

export default CloneSubhead;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../../styled/CommonComponents/NormalDrawer";
import moment from "moment";
import Skeleton from '@material-ui/lab/Skeleton';
import emptyfolder from "../../../Assets/FileIcon/emptyfolder.png"
import { getAllBudgetUpdateByGivenFilter } from "../api.call";
import config from "../../../config/index";
import DrawerTable from "../SubheadDetailsDialog/DrawerTable";

const useStyles = makeStyles((theme) => ({
    bodyCont: {
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    skeletonSty: {
        width: "100%",
        height: "35px",
        marginBottom: "5px"
    },
    emptyCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "14px",
            color: "gray",
            marginTop: "5px"
        },
        "& img": {
            width: "250px",
            height: "auto"
        }
    }
}));

const ApprovedAmountDetails = ({
    openApprovedAmountDetails, setOpenApprovedAmountDetails,
    selectedWorkitemData, parentChildrenMap, getInitialData
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const [allBudgetUpdateds, setAllBudgetUpdateds] = useState([])
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        if (selectedWorkitemData?._id) {
            setLoader(true)
            let childrenIdsArr = parentChildrenMap[selectedWorkitemData?._id] || []
            let idsArr = [selectedWorkitemData?._id, ...childrenIdsArr]

            getAllBudgetUpdateByGivenFilter({
                givenFilter: {
                    $and: [
                        { workitem: { $in: idsArr } },
                        {
                            $or: [
                                { status: "Approved" },
                                { status: "Certifite" },
                                { status: "Paid" }
                            ]
                        }
                    ]
                }
            })
                .then((data) => {
                    let locUpdateItem = []
                    var isDev = config?.mode == 'dev'

                    data.map((item) => {
                        locUpdateItem.push({
                            ...item,
                            title: item?.opsOrder?.name,
                            date: moment(item?.createdAt).format("DD/MM/YYYY"),
                            link: isDev?`${config.BASE_URL}procurements/ops-order/${item?.opsOrder?._id}/edit`:
                            `https://operations.reallist.ai/procurements/ops-order/${item?.opsOrder?._id}/edit`
                        })
                    })
                    setAllBudgetUpdateds(locUpdateItem)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [selectedWorkitemData?._id])


    return (
        <NormalDrawer
            openDrawer={openApprovedAmountDetails}
            setOpenDrawer={setOpenApprovedAmountDetails}
            anchor={"right"}
            width={"50vw"}
            title={"Approved Amount Details"}
            content={
                <div className={classes.bodyCont} >
                    {loader ? (<>
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                    </>) : (<>
                        {allBudgetUpdateds && allBudgetUpdateds.length > 0 ? allBudgetUpdateds.length > 0 && (
                            <DrawerTable
                                allBudgetUpdateds={allBudgetUpdateds}
                                setAllBudgetUpdateds={setAllBudgetUpdateds}
                                reloadData={getInitialData}
                                amountField={"approvalAmount"}
                            />
                        ) : (<div className={classes.emptyCont} >
                            <img src={emptyfolder} />
                            <p>No budget-update connected with current subhead</p>
                        </div>)}
                    </>)}
                </div>
            }
        />
    );
};

export default ApprovedAmountDetails;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import { createBudgetCostApi, updateBudgetCostById } from "./api.call";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import ProjectBlockCreate from "../team/ProjectBlockCreate";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "300px"
    },
    btnCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "15px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    checkBoxTextCont: {
        display: "flex",
        alignItems: "center",
        "& p": {
            fontSize: "14px",
            fontWeight: "400"
        }
    },
}));

const CreateBudgetCost = ({
    openBudgetCost, setOpenBudgetCost, projectBlocks = [], addNewItemFun, 
    budgetCostObj, defaultTemplate, profileData, projectId
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [title, setTitle] = useState("");
    const [selectedBlock, setSelectedBlock] = useState({})
    const [isOpenEditView, setIsOpenEditView] = useState(true)
    const [loader, setLoader] = useState(false)
    const [curBlocksArr, setCurBlocksArr] = useState([])
    const [showCreateProjectBlockDialog, setShowCreateProjectBlockDialog] = useState(false);

    useEffect(() => {
        if (budgetCostObj?._id) {
            setSelectedBlock(budgetCostObj?.projectBlock || null)
        } else {
            setSelectedBlock(projectBlocks && projectBlocks.length > 0 ? projectBlocks[0] : null)
        }
        setTitle(budgetCostObj?.title)

        const newObj = {
            _id: "_new",
            name: "+ Add new"
        }
        const newArr = [newObj, ...projectBlocks]
        setCurBlocksArr(newArr)
    }, [projectBlocks, budgetCostObj])

    const onSubmitAddEdit = async (e) => {
        setLoader(true)
        e.preventDefault();
        if (budgetCostObj?._id) {
            await updateBudgetCostById({
                _id: budgetCostObj?._id,
                profile: budgetCostObj?.profile,
                user: budgetCostObj?.user,
                projectId: budgetCostObj?.projectId,
                title: title,
                projectBlock: selectedBlock?._id
            })
                .then((data) => {
                    setTitle("")
                    setSelectedBlock({})
                    setIsOpenEditView(true)
                    setOpenBudgetCost(false)
                    if (addNewItemFun) {
                        addNewItemFun(data, false)
                    }
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            let newObj = {
               profile: budgetCostObj?.profile,
                user: budgetCostObj?.user,
                userProfile: user?.profile,
                title: title,
                libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
                projectBlock: selectedBlock?._id,
                defaultTemplate: profileData?.parent?.wallet?.defaultLibrary?.defaultEstimationApproval?._id
            };
            if (profileData?.parentModelName === "Project") {
                newObj.projectParent = profileData?.parent?.ownerProfile?.parent;
                newObj.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
                newObj.project = profileData?.parent?._id;
            } else {
                newObj.projectParent = profileData?.parent?._id;
                newObj.projectParentModel = profileData?.parentModelName;
            }
            if (profileData?.parentModelName === "Organization") {
                newObj.orgId = profileData?.parent?._id
            } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
                newObj.orgId = profileData?.parent?.ownerProfile?.parent
            }

            await createBudgetCostApi(newObj)
                .then((data) => {
                    setTitle("")
                    setSelectedBlock({})
                    setIsOpenEditView(true)
                    setOpenBudgetCost(false)
                    if (isOpenEditView) {
                        history.push(`/budget-analysis/budget-cost/edit/${data?._id}`);
                    } else {
                        if (addNewItemFun) {
                            addNewItemFun(data, true)
                        }
                    }
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const addNewBlockItemFun = (item) => {
        const newObj = {
            _id: "_new",
            name: "+ Add new"
        }
        const len = curBlocksArr.length;
        const originalArr = curBlocksArr.slice(1, len - 1);
        const newArr = [newObj, item, ...originalArr]
        setCurBlocksArr(newArr)
        setSelectedBlock(item)
    }

    return (
        <NormalDialog
            openDialog={openBudgetCost}
            handleCloseShare={() => { setOpenBudgetCost(false) }}
            pageTitle={budgetCostObj?._id ? "Edit Budget Cost" : "Add Budget Cost"}
            content={<>
                <form onSubmit={onSubmitAddEdit} >
                    <div className={classes.mainCont} >
                        <TextField
                            variant="outlined"
                            value={title}
                            onChange={(e) => { setTitle(e?.target?.value); }}
                            fullWidth
                            placeholder={"Enter Title"}
                            style={{ marginBottom: "15px" }}
                            size="small"
                        />
                        <Autocomplete
                            value={selectedBlock}
                            options={curBlocksArr}
                            getOptionLabel={(option) => { return option?.name }}
                            getOptionSelected={(option) => {
                                return option?._id === selectedBlock?._id
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ margin: "0px" }}
                                    variant="outlined"
                                    label="Project Block"
                                    placeholder={"Select Project Block"}
                                />
                            )}
                            onChange={(event, value) => {
                                if (value?._id === "_new") {
                                    setShowCreateProjectBlockDialog(true)
                                } else {
                                    setSelectedBlock(value);
                                }
                            }}
                            fullWidth
                            size="small"
                        />
                        <div className={classes.checkBoxTextCont} >
                            <Checkbox
                                color="primary"
                                checked={isOpenEditView}
                                onChange={(e) => {
                                    setIsOpenEditView(e.target.checked)
                                }}
                            />
                            <p>Open edit view</p>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "4px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.btnCont} >
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="small"
                            disabled={loader}
                        >
                            save
                        </Button>
                    </div>
                </form>
                <ProjectBlockCreate
                    showCreateProjectBlockDialog={showCreateProjectBlockDialog}
                    setShowCreateProjectBlockDialog={setShowCreateProjectBlockDialog}
                    addNewItemFun={addNewBlockItemFun}
                    projectId={projectId}
                    profileId={budgetCostObj?.profile}
                />
            </>}
        />
    );
};

export default CreateBudgetCost;
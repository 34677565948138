import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Button, Paper } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteBudgetCostHead, deleteBudgetCostSubhead, getBudgetCostById, updateBudgetCostById } from "./api.call";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import Checkbox from '@material-ui/core/Checkbox';
import { useDebounce } from 'react-use';
import { MdOutlineForkRight } from "react-icons/md";
import BudgetCostHeadCreate from "./BudgetCostHeadCreate";
import BudgetCostHead from "./BudgetCostHead";
import Skeleton from '@material-ui/lab/Skeleton';
import BudgetCostSkeleton from "../styled/skeletons/BudgetCostSkeleton";
import emptyFile from "../../Assets/emptyData.svg"
import EjectIcon from '@material-ui/icons/Eject';
import DeleteIcon from '@material-ui/icons/Delete';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from "react-icons/io";
import CloseIcon from '@material-ui/icons/Close';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import { CgArrowsBreakeV } from "react-icons/cg";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import { ArrowDropDown } from "@material-ui/icons";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CloneProjectHead from "./CloneProjectHead";
import CloneSubhead from "./CloneSubheads";
import ApprovedAmountDetails from "./SubheadDetailsDialog/ApprovedAmountDetails";
import GrossValueCertifiteDetails from "./SubheadDetailsDialog/GrossValueCertifiteDetails";
import NetCertifitePayableDetails from "./SubheadDetailsDialog/NetCertifitePayableDetails";
import PaidDetails from "./SubheadDetailsDialog/PaidDetails";
import SubmittedAmountDetails from "./SubheadDetailsDialog/SubmittedAmountDetails";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import ApprovalAndActions from "../approval/ApprovalAndActions";
import LockIcon from '@material-ui/icons/Lock';
import ApprovalActionDrawer from "../approval/ApprovalActionDrawer";
import { allStatusOptions } from "../../helpers/allStatuses";
import LinkedTasksDetails from "./SubheadDetailsDialog/LinkedTasksDetails";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    headerCont: {
        width: "100%",
        height: "60px",
        borderBottom: "1px solid #ebeaea",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px"
    },
    bodyCont: {
        width: "100%",
        height: "calc(100vh - 60px)",
        overflowY: "auto",
        overflowX: "auto",
        padding: "25px 15px 90px",
        position: "relative",
    },
    rightSide: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-root": {
            border: "1px solid gray"
        },
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontWeight: "510",
            fontSize: "14px"
        },
        "& p": {
            fontSize: "13px",
            fontWeight: "500",
            margin: "0px 10px"
        }
    },
    leftSide: {
        display: "flex",
        alignItems: "center",
        "& h3": {
            fontSize: "19px",
            fontWeight: "600"
        }
    },
    tablesCont: {
        width: "100%"
    },
    addButton: {
        marginLeft: "10px",
        "& .MuiButton-root": {
            border: "none"
        },
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    mainPopCont: {
        padding: "10px",
        "& h3": {
            fontSize: "16px",
            fontWeight: "600",
            marginBottom: "10px"
        },
        "& h4": {
            fontSize: "16px",
            fontWeight: "450",
            marginTop: "10px"
        }
    },
    checkBoxTextCont: {
        display: "flex",
        alignItems: "center",
        "& p": {
            fontSize: "16px",
            fontWeight: "450"
        }
    },
    mainPopContForCostIn: {
        padding: "0px",
        "& p": {
            fontSize: "16px",
            fontWeight: "400",
            padding: "4px 10px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#ececec"
            }
        }
    },
    bottomTotalCont: {
        width: "2650px",
        border: "1px solid gray",
        borderCollapse: "collapse",
        tableLayout: "auto",
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px"
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
            textAlign: "right",
            paddingRight: "10px"
        }
    },
    emptyCont: {
        width: "100%",
        height: "calc(100vh - 100px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& img": {
            width: "200px",
            height: "auto"
        },
        "& p": {
            fontSize: "13px",
            color: "gray",
            marginTop: "20px"
        },
        "& a": {
            cursor: "pointer",
            color: theme.palette.primary.main
        }
    },
    showChildIcon: {
        transform: "rotate(180deg)",
        transition: "all .3s ease-in-out",
        color: "white",
        color: "black",
        margin: "0px 3px",
    },
    hideChildIcon: {
        transform: "rotate(90deg)",
        transition: "all .3s ease-in-out",
        color: "white",
        color: "black",
        margin: "0px 3px"
    },
    width200: {
        width: "200px"
    },
    width150: {
        width: "150px"
    },
    deleteBtn: {
        width: "0px",
        position: "relative",
        "& .MuiIconButton-root": {
            position: "absolute",
            top: "4px",
            left: "5px",
            padding: "5px"
        }
    },
    bottomBarForDeleteShow: {
        width: "calc(100% - 110px)",
        padding: "5px 10px",
        position: "fixed",
        bottom: "0px",
        right: "0px",
        backgroundColor: "white",
        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all .3s ease-in-out",
    },
    bottomBarForDeleteHide: {
        width: "calc(100% - 110px)",
        padding: "5px 10px",
        position: "fixed",
        bottom: "-80px",
        right: "0px",
        backgroundColor: "white",
        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all .3s ease-in-out",
    },
    bottomRightSide: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-root": {
            border: "1px solid black"
        },
        "& p": {
            margin: "0px 10px"
        }
    },
    textIconCont: {
        display: "flex",
        alignItems: "center",
        marginRight: "15px",
        "& p": {
            fontSize: "13px",
            color: "gray"
        }
    },
    divider: {
        height: "25px",
        width: "2px",
        backgroundColor: "gray"
    },
    iconText: {
        fontSize: "15px",
        marginLeft: "5px"
    },
    selectText: {
        fontSize: "14px",
        color: "gray"
    },
    deleteConfirm: {
        width: "350px",
        padding: "25px 0px 35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .MuiSvgIcon-root": {
            fontSize: "75px",
            color: "red"
        },
        "& h3": {
            fontSize: "20px"
        },
        "& p": {
            fontSize: "14px"
        }
    },
    overflowCont: {
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
    },
    overlayLoader: {
        top: "60px",
        left: "7rem",
        width: "calc(100vw - 7rem)",
        height: "calc(100vh - 60px)",
        padding: "25px",
        zIndex: "99",
        position: "Fixed",
        backgroundColor: "white",
        [theme.breakpoints.only("xs")]: {
            left: "0rem",
            width: "100vw",
        }
    },
    optionsCont: {
        padding: "5px",
    },
    typeSingleOptionSty: {
        padding: "4px 15px",
        fontSize: "16px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec",
        },
    },
}));

const BudgetCostAnalysisEdit = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { budgetCostId } = useParams();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const bodyRef = useRef(null);

    const [budgetCost, setBudgetCost] = useState(null)
    const [budgetedCostColumns, setBudgetedCostColumns] = useState([])
    const [estimatedCostColumns, setEstimatedCostColumns] = useState([])
    const [costingInArr, setCostingInArr] = useState([])
    const [costingInVal, setCostingInVal] = useState(null)
    const [costingPerArr, setCostingPerArr] = useState([])
    const [costingPerVal, setCostingPerVal] = useState(null)
    const [openHeadCreate, setOpenHeadCreate] = useState(false)
    const [costHeads, setCostHeads] = useState([]);
    const [loader, setLoader] = useState(false)
    const [headCreateLoader, setHeadCreateLoader] = useState(false)
    const [costHeadTotalObj, setCostHeadTotalObj] = useState([])
    const [headStateChange, setHeadStateChange] = useState(false)
    const [expandAllHead, setExpandAllHead] = useState(true)
    const [budgetedCostColp, setBudgetedCostColp] = useState(2)
    const [estimatedCostColp, setEstimatedCostColp] = useState(5)
    const [columnsObj, setColumnsObj] = useState({
        "Basic estimated cost": true,
        "Cost Variance": true,
        "Cost per area": true,
        "Cost per area with GST": true,
        "Cost per area without GST": true,
        "GST": true,
        "Total Budgeted cost": true,
        "Total cost": true
    })
    const [selectedSubheadIds, setSelectedSubheadIds] = useState([])
    const [deleteAbleBudgetHeadIds, setDeleteAbleBudgetHeadIds] = useState([])
    const [deleteAbleBudgetSubheadIds, setDeleteAbleBudgetSubheadIds] = useState([])
    const [headDeleteOpen, setHeadDeleteOpen] = useState(false)
    const [subheadDeleteOpen, setSubheadDeleteOpen] = useState(false)
    const [headDeleteLoader, setHeadDeleteLoader] = useState(false)
    const [subheadDeleteLoader, setSubheadDeleteLoader] = useState(false)
    const [selectedBudgetHead, setSelectableBudgetHead] = useState(null)
    const [cloneDialogOpen, setCloneDialogOpen] = useState(false)
    const [copyDialogOpen, setcopyDialogOpen] = useState(false)
    const [openPaidDetails, setOpenPaidDetails] = useState(false)
    const [openNetCertifitePayableDetails, setOpenNetCertifitePayableDetails] = useState(false)
    const [openGrossValueCertifiteDetails, setOpenGrossValueCertifiteDetails] = useState(false)
    const [openApprovedAmountDetails, setOpenApprovedAmountDetails] = useState(false)
    const [openSubmittedAmountDetails, setOpenSubmittedAmountDetails] = useState(false)
    const [selectedSubhead, setSelectedSubhead] = useState(false)
    const [totalAmountFlag, setTotalAmountFlag] = useState(false)
    const [openApprovalFlow, setOpenApprovalFlow] = useState(false)
    const [openAction, setOpenAction] = useState(false)
    const [needToMakeDisable, setNeedToMakeDisable] = useState(false)
    const [needToMakeStatusDisable, setNeedToMakeStatusDisable] = useState(true)
    const [actionStatus, setActionStatus] = useState("")
    const [activeStage, setActiveStage] = useState(null)
    const [taskOpenData, setTaskOpenData] = useState({
        open: false,
        taskIds: []
    })

    useEffect(() => {
        bodyRef.current.scrollTo(0, 0);
    }, [])

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    //initial call
    const getBudgetCostPopulatedData = async (showLoader) => {
        if (showLoader) {
            setLoader(true)
        }
        await getBudgetCostById({
            budgetCostId: budgetCostId
        })
            .then(async (data) => {
                console.log(data)
                setBudgetCost(data)
                setCostHeads(data?.budgetHead || [])
                setBudgetedCostColumns(data?.budgetCostColumns || [])
                setEstimatedCostColumns(data?.estimatedCostColumns || [])
                setCostingInArr(data?.costingInArr || [])
                setCostingPerArr(data?.costingPerArr || [])
                setCostingPerVal(data?.costingPerValue || null)
                setCostingInVal(data?.costingInValue || null)
                if (showLoader) {
                    if (data?.budgetHead && data.budgetHead.length > 0) {
                        await timeout(5000)
                    }
                    setLoader(false)
                    bodyRef.current.scrollTo(0, 0);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //call api to get inital data 
    useEffect(() => {
        getBudgetCostPopulatedData(true)
    }, [])

    useEffect(() => {
        let locActiveStage = budgetCost?.approvalRequest?.activeStage || null
        setActiveStage(locActiveStage)
        let canEdit = false
        if (locActiveStage?.approvalType === "anybody") {
            canEdit = true
        } else {
            let approvers = locActiveStage?.approvers || [];
            for (let i = 0; i < approvers.length; i++) {
                if (approvers[i].profile == user?.profile && !approvers[i]?.isApproved && !approvers[i]?.isRejected) {
                    canEdit = true;
                    break;
                }
            }
        }
        setNeedToMakeStatusDisable(!canEdit)
        if (locActiveStage?.canEditMainData && canEdit) {
            setNeedToMakeDisable(false)
        } else {
            setNeedToMakeDisable(true)
        }
    }, [budgetCost?.approvalRequest?.activeStage])

    //change columns configuration
    useEffect(() => {
        const obj = {}
        let budgetedCount = 0;
        let estimatedCount = 0;
        budgetedCostColumns.map((budgetCostColumn) => {
            obj[budgetCostColumn?.name] = budgetCostColumn?.bool;
            if (budgetCostColumn?.bool) {
                budgetedCount = budgetedCount + 1;
            }
        })
        estimatedCostColumns.map((estimatedCostColumn) => {
            obj[estimatedCostColumn?.name] = estimatedCostColumn?.bool
            if (estimatedCostColumn?.name !== "Cost Variance" && estimatedCostColumn?.bool) {
                estimatedCount = estimatedCount + 1;
            }
        })

        setBudgetedCostColp(budgetedCount)
        setEstimatedCostColp(estimatedCount)
        setColumnsObj(obj)
    }, [budgetedCostColumns, estimatedCostColumns])

    //to handle budgeted cost columns (show/hide)
    const onChangeBudgetedCostColumns = async (locBudgetedCostColumns) => {
        setBudgetedCostColumns(locBudgetedCostColumns);
        await updateBudgetCostById({
            _id: budgetCost?._id,
            budgetCostColumns: locBudgetedCostColumns
        })
            .then((data) => {
                console.log("data")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //to handle extimated cost columns (show/hide)
    const onChangeEstimatedCostColumns = async (locEstimatedCostColumns) => {
        setEstimatedCostColumns(locEstimatedCostColumns);
        await updateBudgetCostById({
            _id: budgetCost?._id,
            estimatedCostColumns: locEstimatedCostColumns
        })
            .then((data) => {
                console.log("data")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //to handle costing in value
    const onChangeCostingInVal = async (locCostingInVal) => {
        setCostingInVal(locCostingInVal)
        await updateBudgetCostById({
            _id: budgetCost?._id,
            costingInValue: locCostingInVal
        })
            .then((data) => {
                console.log("data")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //to handle costing per (selecting area type)
    const onChangeCostingPerVal = async (locCostingPerVal) => {
        setCostingPerVal(locCostingPerVal)
        await updateBudgetCostById({
            _id: budgetCost?._id,
            costingPerValue: locCostingPerVal
        })
            .then((data) => {
                console.log("data")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //add new project head
    const addNewCreatedHead = (newHead) => {
        let arr = [...costHeads, newHead]
        setCostHeads(arr)
    }

    //function to add coma with the number input field
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //useEffect to update grand total values
    useEffect(() => {
        setTotalAmountFlag(!totalAmountFlag)
        let totalBudgetCost = 0;
        let budgetCostPerArea = 0;
        let basicEstimatedCost = 0;
        let totalGst = 0;
        let totalEstimate = 0;
        let costPerAreaWithoutGst = 0;
        let totalVariance = 0;
        let totalArea = 0;
        let areaInSqft = 0;
        let totalLocTotalApprovedAmount = 0
        let totalLocTotalSubmittedAmount = 0
        let totalLocTotalTxInitialAmount = 0
        let totalLocTotalPaidAmount = 0
        let totalLocOutstanding = 0
        let totalLocBudgetBalance = 0
        let totalTaskNumbers = 0;

        costHeads && costHeads.map((costHeadData) => {
            let locSubhead = costHeadData?.items || [];
            let locAreaUnitArr = costHeadData?.areaUnitArr || [];
            let selectedAreaUnitValue = null;

            locAreaUnitArr.map((areaUnitData) => {
                if (areaUnitData?.lableName === costingPerVal) {
                    selectedAreaUnitValue = areaUnitData
                }
            })
            if (selectedAreaUnitValue?.unit === "Sqft") {
                if (costingInVal?.areaUnit === "Sqft") {
                    areaInSqft = parseFloat(selectedAreaUnitValue?.amount || 1).toFixed(2)
                } else {
                    areaInSqft = parseFloat(parseFloat(selectedAreaUnitValue?.amount || 1) / 10.76391042).toFixed(2)
                }
            } else {
                if (costingInVal?.areaUnit === "Sqft") {
                    areaInSqft = parseFloat(parseFloat(selectedAreaUnitValue?.amount || 1) * 10.76391042).toFixed(2);
                } else {
                    areaInSqft = parseFloat(selectedAreaUnitValue?.amount || 1).toFixed(2)
                }
            }
            totalArea = parseFloat(parseFloat(totalArea) + parseFloat(areaInSqft)).toFixed(2)

            locSubhead.map((subheadData) => {
                totalTaskNumbers = totalTaskNumbers + parseInt(subheadData?.numberOfTasks || 0)
                totalBudgetCost = totalBudgetCost + parseFloat(subheadData?.totalBudgetCost || 0);
                basicEstimatedCost = basicEstimatedCost + parseFloat(subheadData?.totalEstimatedCost || 0);
                totalGst = totalGst + parseFloat(subheadData?.totalEstimatedGST || 0);
                totalEstimate = totalEstimate + parseFloat(subheadData?.totalEstimatedGST || 0) + parseFloat(subheadData?.totalEstimatedCost || 0);
                totalVariance = totalVariance + (parseFloat(subheadData?.totalBudgetCost || 0) - (parseFloat(subheadData?.totalEstimatedGST || 0) + parseFloat(subheadData?.totalEstimatedCost || 0)))
                budgetCostPerArea = budgetCostPerArea + parseFloat(parseFloat(subheadData?.totalBudgetCost || 0) / areaInSqft).toFixed(2)
                costPerAreaWithoutGst = costPerAreaWithoutGst + (parseFloat(subheadData?.totalEstimatedCost || 0) / areaInSqft).toFixed(2)
                totalLocTotalApprovedAmount = totalLocTotalApprovedAmount + parseFloat(subheadData?.totalApprovedAmount || 0)
                totalLocTotalSubmittedAmount = totalLocTotalSubmittedAmount + parseFloat(subheadData?.totalSubmittedAmount || 0)
                totalLocTotalTxInitialAmount = totalLocTotalTxInitialAmount + parseFloat(subheadData?.totalTxInitialAmount || 0)
                totalLocTotalPaidAmount = totalLocTotalPaidAmount + parseFloat(subheadData?.totalPaidAmount || 0)
                totalLocOutstanding = totalLocOutstanding + parseFloat(parseFloat(subheadData?.totalTxInitialAmount || 0) - parseFloat(subheadData?.totalPaidAmount || 0))
                totalLocBudgetBalance = totalLocBudgetBalance + parseFloat((parseFloat(subheadData?.totalEstimatedGST || 0) + parseFloat(subheadData?.totalEstimatedCost || 0)) - parseFloat(subheadData?.totalApprovedAmount || 0))
            })
        })

        setCostHeadTotalObj({
            totalBudgetCost,
            budgetCostPerArea,
            basicEstimatedCost,
            totalGst,
            totalEstimate,
            totalVariance,
            costPerAreaWithGst: (parseFloat(totalEstimate / totalArea)).toFixed(2),
            costPerAreaWithoutGst,
            totalArea,
            totalTaskNumbers,
            totalLocTotalApprovedAmount,
            totalLocTotalSubmittedAmount,
            totalLocTotalTxInitialAmount,
            totalLocTotalPaidAmount,
            totalLocOutstanding,
            totalLocBudgetBalance
        })
        setTotalAmountFlag(!totalAmountFlag)
    }, [costHeads, costingPerVal, costingInVal, headStateChange])

    useDebounce(() => {
        if (budgetCost?._id && (budgetCost?.totalBudgetAmount !== costHeadTotalObj?.totalBudgetCost || budgetCost?.totalGrossAmount !== costHeadTotalObj?.totalEstimate || budgetCost?.totalPaidAmount !== costHeadTotalObj?.totalLocTotalPaidAmount)) {
            updateBudgetCostById({
                _id: budgetCost?._id,
                totalBudgetAmount: parseFloat(costHeadTotalObj?.totalBudgetCost || 0).toFixed(2),
                totalGrossAmount: parseFloat(costHeadTotalObj?.totalEstimate || 0).toFixed(2),
                totalPaidAmount: parseFloat(costHeadTotalObj?.totalLocTotalPaidAmount || 0).toFixed(2),
            })
                .then((data) => {
                    console.log("data")
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, 1000, [totalAmountFlag, costHeadTotalObj])

    const cancelSelect = () => {
        setSelectedSubheadIds([])
        setDeleteAbleBudgetHeadIds([])
        setDeleteAbleBudgetSubheadIds([])
        setHeadDeleteOpen(false)
        setSubheadDeleteOpen(false)
        setHeadDeleteLoader(false)
        setSubheadDeleteLoader(false)
    }

    const deleteBudgetHeadByIds = async (locDeleteAbleBudgetHeadIds) => {
        setHeadDeleteLoader(true)
        await deleteBudgetCostHead({
            headIds: locDeleteAbleBudgetHeadIds,
            budgetCostId: budgetCost?._id
        })
            .then(async (data) => {
                await getBudgetCostPopulatedData(false)
                cancelSelect()
                setHeadDeleteLoader(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const deleteBudgetSubheadByIds = async (locDeleteAbleBudgetSubheadIds) => {
        setSubheadDeleteLoader(true)
        const headSubheadObjArr = []
        costHeads && costHeads.map((costHead) => {
            let arr = costHead?.items || []
            let subIdsArr = [];
            arr && arr.map((subhead) => {
                if (locDeleteAbleBudgetSubheadIds.includes(subhead?._id)) {
                    subIdsArr.push(subhead?._id)
                }
            })
            if (subIdsArr && subIdsArr.length > 0) {
                headSubheadObjArr.push({
                    budgetWorkHeadId: costHead?._id,
                    budgetSubheads: subIdsArr
                })
            }
        })

        await deleteBudgetCostSubhead({
            allSubheadIds: locDeleteAbleBudgetSubheadIds,
            headSubheadObjArr,
        })
            .then(async (data) => {
                await getBudgetCostPopulatedData(false)
                cancelSelect()
                setSubheadDeleteLoader(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const updateStatus = async (curStatus) => {
        setActionStatus(curStatus)
        setOpenAction(true)
    }

    return (
        <div className={classes.mainCont} >
            <div className={classes.headerCont} >
                <div className={classes.leftSide} >
                    {loader ? (<>
                        <Skeleton variant="rect" style={{ width: "140px", height: "40px", marginRight: "7px" }} />
                        <Skeleton variant="rect" style={{ width: "35px", height: "35px", marginRight: "7px" }} />
                        <Skeleton variant="rect" style={{ width: "35px", height: "35px", marginRight: "7px" }} />
                    </>) : (<>
                        <h3>Cost Summary</h3>
                        <IconButton onClick={() => { setExpandAllHead(!expandAllHead) }} >
                            <EjectIcon className={expandAllHead ? classes.showChildIcon : classes.hideChildIcon} />
                        </IconButton>
                        <IconButton disabled >
                            <CgArrowsBreakeV style={{ color: "black", margin: "0px 3px" }} />
                        </IconButton>
                    </>)}
                </div>
                <div className={classes.rightSide} >
                    {loader ? (<>
                        <Skeleton variant="rect" style={{ width: "120px", height: "40px", marginRight: "7px" }} />
                        <Skeleton variant="rect" style={{ width: "120px", height: "40px", marginRight: "7px" }} />
                        <Skeleton variant="rect" style={{ width: "120px", height: "40px", marginRight: "7px" }} />
                        <Skeleton variant="rect" style={{ width: "140px", height: "40px", marginRight: "7px" }} />
                    </>) : (<>
                        <MyPopOver
                            closeOnClick={false}
                            appearContent={
                                <Button
                                    size="small"
                                    endIcon={<ArrowDropDownIcon />}
                                >Columns</Button>
                            }
                            showContent={<div className={classes.mainPopCont} >
                                <h3>Columns shown</h3>
                                {budgetedCostColumns && budgetedCostColumns.length > 0 && (<>
                                    <h4>Budgeted Cost</h4>
                                    <div>
                                        {budgetedCostColumns && budgetedCostColumns.map((budgetedCostColumnData, i) => (<div key={i} className={classes.checkBoxTextCont} >
                                            <Checkbox
                                                color="primary"
                                                checked={budgetedCostColumnData?.bool}
                                                onChange={(event) => {
                                                    const arr = [...budgetedCostColumns]
                                                    arr[i].bool = !arr[i].bool
                                                    onChangeBudgetedCostColumns(arr);
                                                }}
                                            />
                                            <p>{budgetedCostColumnData?.name}</p>
                                        </div>))}
                                    </div>
                                </>)}
                                {estimatedCostColumns && estimatedCostColumns.length > 0 && (<>
                                    <h4>Estimated Cost</h4>
                                    <div>
                                        {estimatedCostColumns && estimatedCostColumns.map((estimatedCostColumnData, i) => (<div key={i} className={classes.checkBoxTextCont} >
                                            <Checkbox
                                                color="primary"
                                                checked={estimatedCostColumnData?.bool}
                                                onChange={(event) => {
                                                    const arr = [...estimatedCostColumns]
                                                    arr[i].bool = !arr[i].bool
                                                    onChangeEstimatedCostColumns(arr);
                                                }}
                                            />
                                            <p>{estimatedCostColumnData?.name}</p>
                                        </div>))}
                                    </div>
                                </>)}
                            </div>}
                        />
                        <p>Costing in</p>
                        <MyPopOver
                            closeOnClick={true}
                            appearContent={
                                <Button
                                    size="small"
                                    endIcon={<ArrowDropDownIcon />}
                                >{`${costingInVal?.moneyUnit}/${costingInVal?.areaUnit}`}</Button>
                            }
                            showContent={<div className={classes.mainPopContForCostIn} >
                                {costingInArr.length > 0 && costingInArr.map((costingInData, i) => (
                                    <p key={i} onClick={() => { onChangeCostingInVal(costingInData) }} >{`${costingInData?.moneyUnit}/${costingInData?.areaUnit}`}</p>
                                ))}
                            </div>}
                        />
                        <p>Costing per</p>
                        <MyPopOver
                            closeOnClick={true}
                            appearContent={
                                <Button
                                    size="small"
                                    endIcon={<ArrowDropDownIcon />}
                                >{costingPerVal}</Button>
                            }
                            showContent={<div className={classes.mainPopContForCostIn} >
                                {costingPerArr.length > 0 && costingPerArr.map((costingPerData, i) => (
                                    <p key={i} onClick={() => { onChangeCostingPerVal(costingPerData) }} >{costingPerData}</p>
                                ))}
                            </div>}
                        />
                        {!needToMakeDisable && (
                            <div className={classes.addButton} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    disabled={headCreateLoader}
                                    onClick={() => { setOpenHeadCreate(true) }}
                                >
                                    Add Project Head
                                </Button>
                                <BudgetCostHeadCreate
                                    openHeadCreate={openHeadCreate}
                                    setOpenHeadCreate={setOpenHeadCreate}
                                    areaTypesArr={costingPerArr}
                                    budgetCostId={budgetCost?._id}
                                    profileId={budgetCost?.profile}
                                    addNewCreatedHead={addNewCreatedHead}
                                    headCreateLoader={headCreateLoader}
                                    setHeadCreateLoader={setHeadCreateLoader}
                                />
                            </div>
                        )}
                        <div style={{ width: "10px" }}></div>
                        <Button
                            id="demo-customized-button"
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => { setOpenApprovalFlow(true) }}
                            endIcon={<MdOutlineForkRight />}
                        >
                            Approval Flow
                        </Button>
                        <ApprovalAndActions
                            openApprovalFlow={openApprovalFlow}
                            setOpenApprovalFlow={setOpenApprovalFlow}
                            loadingBool={loader}
                            setLoadingBool={setLoader}
                            parent={budgetCost?._id}
                            parentModelName={"BudgetCost"}
                            requestId={budgetCost?.approvalRequest?._id}
                            needToMakeDisable={needToMakeStatusDisable}
                            activeStage={activeStage}
                        />
                        <div style={{ width: "10px" }}></div>
                        {needToMakeStatusDisable ? (
                            <Button
                                id="demo-customized-button"
                                variant="outlined"
                                size="small"
                                color="primary"
                                disabled
                                endIcon={<LockIcon />}
                            >
                                {budgetCost?.status ? budgetCost?.status : "STATUS"}
                            </Button>
                        ) : (<>
                            <MyPopOver
                                closeOnClick={true}
                                appearContent={
                                    <Button
                                        id="demo-customized-button"
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        endIcon={<ArrowDropDown />}
                                    >
                                        {budgetCost?.status ? budgetCost?.status : "STATUS"}
                                    </Button>
                                }
                                showContent={<div className={classes.optionsCont} >
                                    {allStatusOptions["Estimation"][budgetCost?.status] && allStatusOptions["Estimation"][budgetCost?.status].map((statusOption, i) => (
                                        <div
                                            key={i}
                                            className={classes.typeSingleOptionSty}
                                            onClick={() => { updateStatus(statusOption) }}
                                        >
                                            {statusOption}
                                        </div>
                                    ))}
                                </div>}
                            />
                            <ApprovalActionDrawer
                                openAction={openAction}
                                setOpenAction={setOpenAction}
                                loadingBool={loader}
                                setLoadingBool={setLoader}
                                actionType={"takeAction"}
                                parent={budgetCost?._id}
                                parentModelName={"BudgetCost"}
                                statusField={"status"}
                                statusValue={actionStatus}
                                requestId={budgetCost?.approvalRequest?._id}
                                activeStage={activeStage}
                                actionCallBack={(type, curStatus) => {
                                    getBudgetCostPopulatedData()
                                }}
                            />
                        </>)}
                    </>)}
                </div>
            </div>
            <div className={classes.bodyCont} ref={bodyRef} >
                {loader && (<div className={classes.overlayLoader} >
                    <BudgetCostSkeleton headerHeight={95} isSubHead={false} />
                </div>)}
                <div className={classes.tablesCont} >
                    {costHeads && costHeads.length > 0 ? (<ScrollSync>
                        <div>
                            {/* heads and subhead container */}
                            {costHeads.length > 0 && costHeads.map((costHeadData, mainIndex) => (
                                <BudgetCostHead
                                    key={mainIndex}
                                    needToMakeDisable={needToMakeDisable}
                                    mainIndex={mainIndex}
                                    costHeadData={costHeadData}
                                    costHeads={costHeads}
                                    setCostHeads={setCostHeads}
                                    costingPerVal={costingPerVal}
                                    projectProfileId={budgetCost.profile}
                                    headStateChange={headStateChange}
                                    setHeadStateChange={setHeadStateChange}
                                    expandAllHead={expandAllHead}
                                    budgetedCostColp={budgetedCostColp}
                                    estimatedCostColp={estimatedCostColp}
                                    columnsObj={columnsObj}
                                    costingInVal={costingInVal}
                                    budgetCostId={budgetCost?._id}
                                    budgetCost={budgetCost}
                                    selectedSubheadIds={selectedSubheadIds}
                                    setSelectedSubheadIds={setSelectedSubheadIds}
                                    setDeleteAbleBudgetSubheadIds={setDeleteAbleBudgetSubheadIds}
                                    setSubheadDeleteOpen={setSubheadDeleteOpen}
                                    setDeleteAbleBudgetHeadIds={setDeleteAbleBudgetHeadIds}
                                    setHeadDeleteOpen={setHeadDeleteOpen}
                                    setSelectableBudgetHead={setSelectableBudgetHead}
                                    setCloneDialogOpen={setCloneDialogOpen}
                                    setcopyDialogOpen={setcopyDialogOpen}
                                    openApprovedAmountDetails={openApprovedAmountDetails}
                                    setOpenApprovedAmountDetails={setOpenApprovedAmountDetails}
                                    openGrossValueCertifiteDetails={openGrossValueCertifiteDetails}
                                    setOpenGrossValueCertifiteDetails={setOpenGrossValueCertifiteDetails}
                                    openNetCertifitePayableDetails={openNetCertifitePayableDetails}
                                    setOpenNetCertifitePayableDetails={setOpenNetCertifitePayableDetails}
                                    openPaidDetails={openPaidDetails}
                                    setOpenPaidDetails={setOpenPaidDetails}
                                    openSubmittedAmountDetails={openSubmittedAmountDetails}
                                    setOpenSubmittedAmountDetails={setOpenSubmittedAmountDetails}
                                    setSelectedSubhead={setSelectedSubhead}
                                    getBudgetCostPopulatedData={getBudgetCostPopulatedData}
                                    setTaskOpenData={setTaskOpenData}
                                />
                            ))}

                            {/* Total Block */}
                            <ScrollSyncPane>
                                <div className={classes.overflowCont} >
                                    <Paper elevation={1} style={{ width: "2650px" }} >
                                        <table className={classes.bottomTotalCont} >
                                            <tbody>
                                                <tr>
                                                    <td className={classes.width200} style={{ textAlign: "left", paddingLeft: "10px", padding: "15px 10px" }} >
                                                        <div>
                                                            <h3 style={{ fontSize: "18px" }} >Grand Total</h3>
                                                        </div>
                                                        <p>{`Total ${costingPerVal}`} : <br /> {`${numberWithCommas(parseFloat(costHeadTotalObj?.totalArea))} ${costingInVal?.areaUnit}`}</p>
                                                    </td>
                                                    <td className={classes.width200} >{numberWithCommas(costHeadTotalObj?.totalTaskNumbers || 0)}</td>
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalBudgetCost || 0).toFixed(2))}</td>
                                                    <td className={classes.width150} >{numberWithCommas(parseFloat(costHeadTotalObj?.budgetCostPerArea || 0).toFixed(2))}</td>
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.basicEstimatedCost || 0).toFixed(2))}</td>
                                                    <td className={classes.width150} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalGst || 0).toFixed(2))}</td>
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalEstimate || 0).toFixed(2))}</td>
                                                    {/* add column start */}
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalLocTotalSubmittedAmount || 0).toFixed(2))}</td>
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalLocTotalApprovedAmount || 0).toFixed(2))}</td>
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalLocTotalTxInitialAmount || 0).toFixed(2))}</td>
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalLocTotalPaidAmount || 0).toFixed(2))}</td>
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalLocOutstanding || 0).toFixed(2))}</td>
                                                    <td className={classes.width200} >{numberWithCommas(parseFloat(costHeadTotalObj?.totalLocBudgetBalance || 0).toFixed(2))}</td>
                                                    {/* add column end */}
                                                    <td className={classes.width200} >
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
                                                            <p>{numberWithCommas(Math.abs(parseFloat(costHeadTotalObj?.totalVariance || 0).toFixed(2)))}</p>
                                                            {parseFloat(costHeadTotalObj?.totalVariance || 0) > 0 ?
                                                                (<IoIosArrowRoundDown style={{ color: "green", fontSize: "23px" }} />)
                                                                : parseFloat(costHeadTotalObj?.totalVariance || 0) === 0 ? null
                                                                    : (<IoIosArrowRoundUp style={{ color: "red", fontSize: "23px" }} />)}
                                                        </div>
                                                    </td>
                                                    {/* <td className={classes.deleteBtn} style={{ padding: "0px" }} ></td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Paper>
                                </div>
                            </ScrollSyncPane>
                        </div>
                    </ScrollSync>) : (<div className={classes.emptyCont} >
                        <img src={emptyFile} />
                        <p>No project head available. <a onClick={() => { setOpenHeadCreate(true) }} >Click here to create</a></p>
                    </div>)}
                </div>
                <div className={selectedSubheadIds && selectedSubheadIds.length > 0 ? classes.bottomBarForDeleteShow : classes.bottomBarForDeleteHide} >
                    <div className={classes.selectText} > {`${selectedSubheadIds.length} Selected`}  </div>

                    <div className={classes.bottomRightSide} >
                        <div className={classes.textIconCont} >
                            <p>Budgeted Cost :</p>
                            <IconButton>
                                <LockOutlinedIcon style={{ fontSize: "19px" }} />
                            </IconButton>
                            <div className={classes.divider} ></div>
                            <IconButton>
                                <LockOpenOutlinedIcon style={{ fontSize: "19px" }} />
                            </IconButton>
                        </div>

                        <div className={classes.textIconCont} >
                            <p>Estimated Cost :</p>
                            <IconButton>
                                <LockOutlinedIcon style={{ fontSize: "19px" }} />
                            </IconButton>
                            <div className={classes.divider} ></div>
                            <IconButton>
                                <LockOpenOutlinedIcon style={{ fontSize: "19px" }} />
                            </IconButton>
                        </div>

                        <IconButton onClick={() => { setcopyDialogOpen(true) }} >
                            <FileCopyIcon />
                            <p className={classes.iconText} >Copy</p>
                        </IconButton>

                        <IconButton onClick={() => {
                            setDeleteAbleBudgetSubheadIds(selectedSubheadIds)
                            setSubheadDeleteOpen(true)
                        }} >
                            <DeleteIcon />
                            <p className={classes.iconText} >Delete</p>
                        </IconButton>

                        <IconButton onClick={cancelSelect} >
                            <CloseIcon />
                            <p className={classes.iconText} >Cancel</p>
                        </IconButton>
                    </div>
                </div>
            </div>
            <DeleteConfirmBox
                open={headDeleteOpen}
                setOpen={setHeadDeleteOpen}
                loading={headDeleteLoader}
                pageTitle={`Delete Budget Head`}
                cantent={
                    <div className={classes.deleteConfirm} >
                        <ReportProblemIcon />
                        <h3>Are you sure?</h3>
                        <p>You won't be able to revert this!</p>
                    </div>
                }
                cancleConfirmDelete={() => {
                    setHeadDeleteOpen(false)
                }}
                confirmDelete={() => {
                    deleteBudgetHeadByIds(deleteAbleBudgetHeadIds)
                }}
            />
            <DeleteConfirmBox
                open={subheadDeleteOpen}
                setOpen={setSubheadDeleteOpen}
                loading={subheadDeleteLoader}
                pageTitle={`Delete Budget Subhead`}
                cantent={
                    <div className={classes.deleteConfirm} >
                        <ReportProblemIcon />
                        <h3>Are you sure?</h3>
                        <p>You won't be able to revert this!</p>
                    </div>
                }
                cancleConfirmDelete={() => {
                    setSubheadDeleteOpen(false)
                }}
                confirmDelete={() => {
                    deleteBudgetSubheadByIds(deleteAbleBudgetSubheadIds)
                }}
            />
            <CloneProjectHead
                cloneDialogOpen={cloneDialogOpen}
                setCloneDialogOpen={setCloneDialogOpen}
                selectedBudgetHead={selectedBudgetHead}
                getBudgetCostPopulatedData={getBudgetCostPopulatedData}
                cancelSelect={cancelSelect}
                budgetCostId={budgetCost?._id}
            />
            <CloneSubhead
                copyDialogOpen={copyDialogOpen}
                setcopyDialogOpen={setcopyDialogOpen}
                selectedSubheadIds={selectedSubheadIds}
                costHeads={costHeads}
                getBudgetCostPopulatedData={getBudgetCostPopulatedData}
                cancelSelect={cancelSelect}
                budgetCostId={budgetCost?._id}
            />
            <SubmittedAmountDetails
                openSubmittedAmountDetails={openSubmittedAmountDetails}
                setOpenSubmittedAmountDetails={setOpenSubmittedAmountDetails}
                selectedSubhead={selectedSubhead}
                needToMakeDisable={needToMakeDisable}
                getBudgetCostPopulatedData={getBudgetCostPopulatedData}
            />
            <ApprovedAmountDetails
                openApprovedAmountDetails={openApprovedAmountDetails}
                setOpenApprovedAmountDetails={setOpenApprovedAmountDetails}
                selectedSubhead={selectedSubhead}
                needToMakeDisable={needToMakeDisable}
                getBudgetCostPopulatedData={getBudgetCostPopulatedData}
            />
            <GrossValueCertifiteDetails
                openGrossValueCertifiteDetails={openGrossValueCertifiteDetails}
                setOpenGrossValueCertifiteDetails={setOpenGrossValueCertifiteDetails}
                selectedSubhead={selectedSubhead}
                needToMakeDisable={needToMakeDisable}
                getBudgetCostPopulatedData={getBudgetCostPopulatedData}
            />
            <PaidDetails
                openPaidDetails={openPaidDetails}
                setOpenPaidDetails={setOpenPaidDetails}
                selectedSubhead={selectedSubhead}
                needToMakeDisable={needToMakeDisable}
                getBudgetCostPopulatedData={getBudgetCostPopulatedData}
            />
            <NetCertifitePayableDetails
                openNetCertifitePayableDetails={openNetCertifitePayableDetails}
                setOpenNetCertifitePayableDetails={setOpenNetCertifitePayableDetails}
                selectedSubhead={selectedSubhead}
                needToMakeDisable={needToMakeDisable}
                getBudgetCostPopulatedData={getBudgetCostPopulatedData}
            />
            <LinkedTasksDetails
                openLinkedTasksDetails={taskOpenData?.open}
                setOpenLinkedTasksDetails={(boolValue) => {
                    setTaskOpenData({
                        ...taskOpenData,
                        open: boolValue
                    })
                }}
                taskIds={taskOpenData?.taskIds}
            />
        </div>
    );
};

export default BudgetCostAnalysisEdit;
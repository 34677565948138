import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LessText from "../../styled/CommonComponents/LessText"
import { IconButton, Button, TextField } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import NormalDialog from "../../styled/CommonComponents/NormalDialog"
import LinearProgress from '@material-ui/core/LinearProgress';
import TextFieldNumberFormated from "../../styled/CommonComponents/TextFieldNumberFormated"
import { updateBudgetUpdateById } from "../api.call";
import config from '../../../config/index'
import { setAuthCookieData } from "../../../helpers/cookie.helper";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    tableStype: {
        width: "100%",
        border: "1px solid gray",
        borderCollapse: "collapse",
        "& tr": {
            height: "35px"
        },
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px",
            textAlign: "center",
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
        },
        "& .MuiIconButton-root": {
            padding: "8px"
        }
    },
    subheadCreateCont: {
        width: "550px"
    },
    twoInputCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
    },
    textFieldSty: {
        width: "48%"
    },
    warningSty: {
        height: "15px",
        fontSize: "9px",
        fontWeight: "400",
        color: "red",
    }
}));

const DrawerTable = ({ allBudgetUpdateds, setAllBudgetUpdateds, reloadData, amountField, needToMakeDisable }) => {
    const classes = useStyles();
    const { user } = useSelector((state) => state.auth);
    const state = useSelector((state) => state);
    const [openEdit, setOpenEdit] = useState(false)
    const [loader, setLoader] = useState(false)
    const [title, setTitle] = useState("")
    const [submittedAmount, setSubmittedAmount] = useState(0)
    const [approvedAmount, setApprovedAmount] = useState(0)
    const [grossValueCertifite, setGrossValueCertifite] = useState(0)
    const [paid, setPaid] = useState(0)
    const [_id, setId] = useState(null)

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const editSubmit = async (e) => {
        e.preventDefault();
        if (_id !== null) {
            setLoader(true)
            await updateBudgetUpdateById({
                _id,
                name: title,
                submittedAmount: submittedAmount,
                approvalAmount: approvedAmount,
                certifiteAmount: grossValueCertifite,
                paidAmount: paid
            })
                .then(async (data) => {
                    console.log(data)
                    let isAmountChange = false
                    let arr = allBudgetUpdateds.map((budgetUpdate) => {
                        if (budgetUpdate?._id == _id) {
                            if (budgetUpdate?.submittedAmount !== data?.submittedAmount || budgetUpdate?.approvalAmount !== data?.approvalAmount || budgetUpdate?.certifiteAmount !== data?.certifiteAmount || budgetUpdate?.paidAmount !== data?.paidAmount) {
                                isAmountChange = true
                            }
                            console.log({
                                ...budgetUpdate,
                                amount: data?.[amountField],
                                [amountField]: data?.[amountField]
                            })
                            return {
                                ...budgetUpdate,
                                amount: data?.[amountField],
                                [amountField]: data?.[amountField]
                            }
                        } else {
                            return budgetUpdate
                        }
                    })
                    console.log(arr)
                    setAllBudgetUpdateds(arr)
                    if (isAmountChange) {
                        await reloadData();
                    }
                    setLoader(false)
                    setOpenEdit(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (<>
        <table className={classes.tableStype} >
            <thead>
                <tr>
                    <th>Budget Line</th>
                    <th>Origin</th>
                    <th style={{ width: "90px" }} >Created At</th>
                    <th>Amount</th>
                    <th style={{ width: "70px" }}>Details</th>
                </tr>
            </thead>
            <tbody>
                {allBudgetUpdateds && allBudgetUpdateds.map((data, i) => (
                    <tr key={i} >
                        <td style={{ textAlign: "left", paddingLeft: "10px" }} title={data?.budgetItem?.name} >
                            {data?.budgetItem?.name ? (
                                <LessText
                                    limit={25}
                                    string={data?.budgetItem?.name}
                                />
                            ) : "Inital Instance"}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: "10px" }} title={data.title} >
                            {data?.title && data?.title.length > 0 ? (
                                <LessText
                                    limit={25}
                                    string={data?.title}
                                />
                            ) : "Inital Instance"}
                        </td>
                        <td style={{ textAlign: "center" }} >{data.date}</td>
                        <td style={{ textAlign: "right", paddingRight: "10px" }} >₹ {numberWithCommas(data?.[amountField] || 0)}</td>
                        <td style={{ textAlign: "center" }} >
                            {data?.title && data?.title.length > 0 ? (
                                <a href='#' onClick={() => {
                                    const isDev = config.mode == 'dev'
                                    if (isDev) { } else {
                                        const token = localStorage.getItem("token");
                                        setAuthCookieData({ user, token });
                                    }

                                    window.open(data.link, '_blank')

                                }}>Link</a>
                            ) : (<>
                                {needToMakeDisable ? (
                                    <IconButton disabled >
                                        <CreateIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => {
                                            setId(data?._id || null)
                                            setSubmittedAmount(data?.submittedAmount || 0)
                                            setApprovedAmount(data?.approvalAmount || 0)
                                            setGrossValueCertifite(data?.certifiteAmount || 0)
                                            setPaid(data?.paidAmount || 0)
                                            setOpenEdit(true)
                                        }}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                )}
                            </>)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

        <NormalDialog
            openDialog={openEdit}
            handleCloseShare={() => { setOpenEdit(false) }}
            pageTitle={"Edit Budget Update"}
            loading={loader}
            content={<form onSubmit={editSubmit} className={classes.subheadCreateCont} >
                <div className={classes.twoInputCont} >
                    <div className={classes.textFieldSty} >
                        <TextFieldNumberFormated
                            label="Submitted Amount"
                            value={submittedAmount}
                            size="small"
                            onChange={(e) => { setSubmittedAmount(e.target.value); }}
                            variant="outlined"
                            placeholder="Enter amount"
                            type="number"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className={classes.textFieldSty} >
                        <TextFieldNumberFormated
                            label="Approved Amount"
                            value={approvedAmount}
                            size="small"
                            onChange={(e) => { setApprovedAmount(e.target.value); }}
                            variant="outlined"
                            placeholder="Enter amount"
                            type="number"
                            style={{ width: "100%" }}
                        />
                        <div className={classes.warningSty} >
                            {parseFloat(approvedAmount || 0) > parseFloat(submittedAmount || 0) ? "Approved amount should be less or equal to submitted amount" : ""}
                        </div>
                    </div>
                </div>
                <div className={classes.twoInputCont} >
                    <div className={classes.textFieldSty} >
                        <TextFieldNumberFormated
                            label="Gross Value Certifite"
                            value={grossValueCertifite}
                            size="small"
                            onChange={(e) => { setGrossValueCertifite(e.target.value); }}
                            variant="outlined"
                            placeholder="Enter amount"
                            type="number"
                            style={{ width: "100%" }}
                        />
                        <div className={classes.warningSty} >
                            {parseFloat(grossValueCertifite || 0) > parseFloat(approvedAmount || 0) ? "Gross value certifite should be less or equal to approved amount" : ""}
                        </div>
                    </div>
                    <div className={classes.textFieldSty} >
                        <TextFieldNumberFormated
                            label="Paid"
                            value={paid}
                            size="small"
                            onChange={(e) => { setPaid(e.target.value); }}
                            variant="outlined"
                            placeholder="Enter paid amount"
                            type="number"
                            style={{ width: "100%" }}
                        />
                        <div className={classes.warningSty} >
                            {parseFloat(paid || 0) > parseFloat(grossValueCertifite || 0) ? "Paid amount should be less or equal to gross value certifite" : ""}
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", height: "4px" }} >
                    {loader && (<LinearProgress />)}
                </div>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        disabled={parseFloat(submittedAmount) >= parseFloat(approvedAmount) && parseFloat(approvedAmount) >= parseFloat(grossValueCertifite) && parseFloat(grossValueCertifite) >= parseFloat(paid) ? loader ? true : false : true}
                    >Save</Button>
                </div>
            </form>}
        />
    </>);
};

export default DrawerTable;
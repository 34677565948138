import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Button, TextField } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDialog from "../styled/CommonComponents/NormalDialog"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createHeadForBudgetCost } from "./api.call";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    amountUnitCont: {
        width: "450px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "25px"
    }
}));

const BudgetCostHeadCreate = ({
    openHeadCreate, setOpenHeadCreate, areaTypesArr,
    budgetCostId, profileId, addNewCreatedHead,
    headCreateLoader, setHeadCreateLoader
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [name, setName] = useState("")
    const [areaUnitVal, setAreaUnitVal] = useState("Sqft")
    const [differentAreaTypes, setDifferentAreaTypes] = useState([])

    const initializeAreaTypesArr = () => {
        let arr = []
        areaTypesArr.map((label) => {
            arr.push({
                name: label,
                amount: 0.00
            })
        })
        setDifferentAreaTypes(arr)
    }

    useEffect(() => {
        initializeAreaTypesArr();
    }, [areaTypesArr])

    const onCreateHead = async (e) => {
        e.preventDefault()
        setHeadCreateLoader(true)
        let arr = [];
        differentAreaTypes.map((areaTypeData) => {
            arr.push({
                lableName: areaTypeData?.name,
                amount: areaTypeData?.amount,
                unit: areaUnitVal
            })
        })

        await createHeadForBudgetCost({
            projectProfileId: profileId,
            userId: user?._id,
            budgetCostId: budgetCostId,
            name: name,
            areaUnitArr: arr
        })
            .then((data) => {
                addNewCreatedHead(data)
                setOpenHeadCreate(false)
                initializeAreaTypesArr()
                setName("")
            })
            .catch((err) => {
                console.log(err)
            })
        setHeadCreateLoader(false)
    }


    return (
        <NormalDialog
            openDialog={openHeadCreate}
            handleCloseShare={() => { setOpenHeadCreate(false) }}
            pageTitle={"Add Budget Cost Head"}
            loading={headCreateLoader}
            content={<form onSubmit={onCreateHead} >
                <div>
                    <TextField
                        label="Name"
                        value={name}
                        size="small"
                        onChange={(e) => { setName(e.target.value); }}
                        variant="outlined"
                        placeholder="Enter head name"
                        style={{ marginBottom: "20px", marginTop: "10px", width: "100%" }}
                    />
                    {differentAreaTypes && differentAreaTypes.map((differentAreaTypeData, i) => (<div key={i} className={classes.amountUnitCont} >
                        <TextField
                            label={`${differentAreaTypeData?.name} (optional)`}
                            value={differentAreaTypeData?.amount}
                            size="small"
                            onChange={(e) => {
                                let arr = [...differentAreaTypes]
                                arr[i].amount = e.target.value
                                setDifferentAreaTypes(arr)
                            }}
                            variant="outlined"
                            placeholder="Enter area value"
                            type="number"
                            style={{ width: "200px" }}
                        />

                        <Autocomplete
                            value={areaUnitVal}
                            options={["Sqft", "Sqmt"]}
                            getOptionLabel={(option) => { return option || "" }}
                            getOptionSelected={(option) => { return option === areaUnitVal }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ margin: "0px" }}
                                    margin="normal"
                                    variant="outlined"
                                    label="Unit"
                                    placeholder={"Select unit"}
                                />
                            )}
                            onChange={(event, value) => {
                                setAreaUnitVal(value)
                            }}
                            style={{ width: "130px" }}
                            size="small"
                        />
                    </div>))}
                </div>
                <div style={{ width: "100%", height: "4px" }} >
                    {headCreateLoader && (<LinearProgress />)}
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "15px" }} >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        disabled={headCreateLoader}
                    >
                        Add
                    </Button>
                </div>
            </form>}
        />
    );
};

export default BudgetCostHeadCreate;
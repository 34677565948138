import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ProfileAppbar from "../profile/profile.appbar";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import * as XLSX from "xlsx";

import { useDispatch, useSelector } from "react-redux";
import Api from "../../helpers/Api";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    padding: "2rem",
    paddingTop: "4.5rem",
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  data1: {
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    flex: "0.5",
  },
  data2: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    flex: "0.5",
  },
  dataChild: {
    display: "flex",
    flexDirection: "column",
  },
  tableSty: {
    minWidth: "100%",
    border: "1px solid gray",
    borderCollapse: "collapse",
    "& th": {
      border: "1px solid gray",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "0px",
    },
    "& td": {
      border: "1px solid gray",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "0px",
      textAlign: "right",
      paddingRight: "10px",
    },
  },
}));

const BudgetUpload = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const file = useSelector((state) => state.budget.file);

  const fieldsToMapNames = ["Category", "SubCategory", "Name", "Rate", "Code"];

  const fieldsToMapObj = {
    category: "Select",
    subCategory: "Select",
    name: "Select",
    rate: "Select",
    code: "Select",
  };

  const [headerRow, setHeaderRow] = useState(0);
  const [mappedFields, setMappedFields] = useState({ ...fieldsToMapObj });

  const [headerRowData, setHeaderRowData] = useState([]);
  const [sheetData, setSheetData] = useState();

  const getColumns = async () => {
    if (file && file.arrayBuffer) {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { defVal: "" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });
      setHeaderRowData(jsonData[headerRow]);
      setSheetData(jsonData);
    } else {
      console.log("No uploaded file found");
    }
  };

  useEffect(() => {
    getColumns();
  }, []);

  useEffect(() => {
    if (sheetData && sheetData[headerRow]) {
      setHeaderRowData(sheetData[headerRow]);
    } else {
      setHeaderRowData({});
    }
  }, [headerRow]);

  const saveData = async () => {
    const isEmpty = Object.values(mappedFields).every(
      (item) => item === null || item === ""
    );

    if (isEmpty) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please specify values for all fields",
        },
      });
      return;
    }
    try {
      const res = await Api.post("/budget-cost/create/by-file", {
        ...mappedFields,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Data saved successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurrd" + err,
        },
      });
    }
  };

  return (
    <div>
      <ProfileAppbar
        name="Configure Library"
        btns={
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveData()}
            >
              Save
            </Button>
          </>
        }
      />
      <Paper elevation={0} style={{ minHeight: "100vh" }}>
        <div className={classes.container}>
          <Typography
            variant="h3"
            style={{ fontWeight: "500", fontSize: "2rem" }}
          >
            Configure Work Items
          </Typography>
          <Typography
            style={{
              fontSize: "1.06rem",
              fontFamily: "Inter",
              fontWeight: "500",
              lineHeight: "1.5",
            }}
          >
            Select the columns that correspond to the provided field of the
            excel sheet
          </Typography>

          <div
            style={{
              fontSize: "1.06rem",
              fontFamily: "Inter",
              fontWeight: "500",
              lineHeight: "1.5",
            }}
          >
            Click{" "}
            <span
              style={{
                backgroundColor: "rgba(135,131,120,0.15)",
                color: "#EB5757",
                fontSize: "1.06rem",
                fontFamily: "Inter",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              Save
            </span>{" "}
            to save your changes.
          </div>
        </div>
        <div style={{ padding: "0rem 2rem 2rem 2rem", display: "flex" }}>
          <div style={{ flex: "0.5" }}>
            <Typography variant="h5">Header Row</Typography>
            <div>
              <TextField
                size="small"
                variant="outlined"
                type="number"
                placeholder="Eg. 1"
                value={headerRow}
                onChange={(e) => setHeaderRow(e.target.value)}
              />
            </div>
          </div>
          <div style={{ flex: "0.5" }}>
            <Typography variant="h5">Rows to ignore</Typography>
            <div>
              <TextField
                size="small"
                variant="outlined"
                type="text"
                placeholder="Eg. 2,5,9"
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "0rem 2rem 2rem 2rem" }}>
          <Typography variant="h5">Preview</Typography>
          <div style={{ minWidth: "100%" }}>
            {headerRowData && headerRowData.length > 0 ? (
              <table className={classes.tableSty}>
                <tr>
                  {headerRowData?.map((item, i) => {
                    if (String(item).trim() !== "") {
                      return <td key={i} >{item}</td>;
                    }
                  })}
                </tr>
              </table>
            ) : (
              <div style={{ color: "#ff0000" }}>Row not found</div>
            )}
          </div>
        </div>

        <div style={{ padding: "0rem 2rem 2rem 2rem" }}>
          <Typography variant="h5">Column Mapping</Typography>
          <div className={classes.main}>
            <div className={classes.data1}>
              <div className={classes.dataChild}>
                {fieldsToMapNames.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    label={item}
                    control={
                      <Checkbox name={item} style={{ color: "#1684ea" }} />
                    }
                    style={{ margin: "5px" }}
                  />
                ))}
              </div>
            </div>
            <div className={classes.data2}>
              {Object.entries(fieldsToMapObj).map(([key, value]) => (
                <FormControl size="small" key={key} >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      const newObj = mappedFields;
                      newObj[key] = e.target.value;
                      setMappedFields(newObj);
                    }}
                    variant="outlined"
                    style={{ margin: "5px" }}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                  >
                    {headerRowData &&
                      headerRowData.length > 0 &&
                      headerRowData?.map((item, i) => {
                        if (String(item).trim() !== "") {
                          return <MenuItem key={i} value={item}>{item}</MenuItem>;
                        }
                      })}
                  </Select>
                </FormControl>
              ))}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default BudgetUpload;

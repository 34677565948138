import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, LinearProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import { createProjectBlock } from "./api.call";

const useStyles = makeStyles((theme) => ({
    btnCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "15px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    }
}))

export default function ProjectBlockCreate({
    showCreateProjectBlockDialog, setShowCreateProjectBlockDialog,
    addNewItemFun, projectId, profileId
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [projectBlockName, setProjectBlockName] = useState("");
    const [loader, setLoader] = useState(false)

    const onClickCreate = async (e, projectId) => {
        e.preventDefault();
        setLoader(true)
        await createProjectBlock({
            projectId,
            userId: user?._id,
            name: projectBlockName,
            profile: profileId
        })
            .then((data) => {
                console.log(data)
                setLoader(false)
                if (addNewItemFun && data && data?.projectBlock) {
                    var projectBlock = data.projectBlock
                    addNewItemFun(projectBlock)
                }
                setShowCreateProjectBlockDialog(false)
            })
            .catch((err) => {
                console.log(err)
            })
    };


    return (
        <NormalDialog
            openDialog={showCreateProjectBlockDialog}
            handleCloseShare={() => {
                setShowCreateProjectBlockDialog(false)
            }}
            pageTitle={"Create Project Block"}
            content={
                <form onSubmit={(e)=>onClickCreate(e,projectId)} >
                    <TextField
                        value={projectBlockName}
                        onChange={(e) => setProjectBlockName(e.target.value)}
                        variant="outlined"
                        label="Title"
                        placeholder="Enter Title"
                        size="small"
                        style={{ width: "350px", margin: "10px 0px 30px" }}
                    />
                    <div style={{ width: "100%", height: "4px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.btnCont} >
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="small"
                            disabled={loader}
                        >
                            save
                        </Button>
                    </div>
                </form>
            }
        />
    );
}
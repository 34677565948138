import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
    }
}));

const BudgetCostSkeleton = ({ headerHeight, isSubHead }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.mainCont} >
            <Skeleton variant="rect" style={{ width: "100%", height: `${headerHeight}px`, marginBottom: "5px" }} />
            <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
            <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
            <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
            <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
            <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
            <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
            <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
            <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />

            {!isSubHead && (<>
                <Skeleton variant="rect" style={{ width: "100%", height: `${headerHeight}px`, marginBottom: "5px", marginTop: "40px" }} />
                <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
                <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
                <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "5px" }} />
            </>)}
        </div>
    );
};

export default BudgetCostSkeleton;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    input: {
        "& input": {
            textAlign: "right",
            fontSize: "15px",
        }
    },
    noBorder: {
        border: "none"
    }
}));

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}

export default function TextFieldNumberWithoutAnyStyle(props) {
    const {
        value, onChange, disabled, placeholder, classStyle, fullWidth
    } = props;
    const classes = useStyles();

    return (
        <TextField
            variant="outlined"
            value={value}
            fullWidth={true}
            onChange={onChange}
            id="formatted-numberformat-input"
            disableUnderline={false}
            autoFocus
            className={classStyle ? classStyle : classes.input}
            placeholder={placeholder}
            InputProps={{
                inputComponent: NumberFormatCustom,
                classes: { notchedOutline: classes.noBorder }
            }}
            disabled={disabled}
        />
    );
}
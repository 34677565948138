import {
  Avatar, AvatarGroup, Box, Tooltip, Typography, tooltipClasses
} from "@mui/material";
import React, { useState } from "react";
import { getProgressColor } from "../../../../helpers/colorHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import HorizBox from "../../../styled/generic/HorizBox";
import { styled } from "@mui/material/styles";
import config from '../../../../config/index'
import { Link } from "react-router-dom/cjs/react-router-dom";

const BudgetTaskCard = ({
  fullTaskView = false,
  task,
  disableBorder = false,
  disableElevation = false,
}) => {
  const history = useHistory();
  const date = new Date(task?.createdAt);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  var isDev = config?.mode == 'dev'

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const getDetailsLink = ()=>{
    if(isDev){
    return `/planning/task/${task?._id}`;
    }else{
      return `https://planning.reallist.ai/planning/task/${task?._id}`
    }
  }

  return (
    <Link to={getDetailsLink} target="_blank" >
      <Box
        sx={{
          minWidth: "20rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "white",
          p: 2,
          borderRadius: "12px",
          border: disableBorder ? "none" : "1px solid rgba(0,0,0,0.10)",
          boxShadow: disableElevation ? "none" : "0px 5px 10px 0px rgba(0, 0, 0, 0.10)",
          cursor: "pointer",
          "&:hover": {
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.20)",
          },
          height: "17rem",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              paddingBottom: "20px",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <Avatar
                alt={task?.creator?.parent?.displayName}
                src={task?.creator?.parent?.displayPicture?.url}
                sx={{ width: 42, height: 42 }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {task?.creator?.parent?.displayName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "semibold", fontSize: "0.9rem" }}
                >
                  {formattedDate}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography
            variant="body1"
          >
            {!fullTaskView ? (
              <>
                {String(task?.taskName).toUpperCase() ? (
                  String(task?.taskName).toUpperCase().length > 80 ? (
                    <>
                      {String(task?.taskName).toUpperCase().slice(0, 80)}.....
                    </>
                  ) : (
                    String(task?.taskName).toUpperCase()
                  )
                ) : (
                  "Untitled"
                )}
              </>
            ) : String(task?.taskName).toUpperCase() ? (
              String(task?.taskName).toUpperCase()
            ) : (
              "Untitled"
            )}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "start", mt: 1 }}>
          <AvatarGroup
            sx={{
              "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 15 },
            }}
            max={10}
          >
            {task?.assigned?.map(({ parent }) => (
              <BootstrapTooltip placement="top" title={parent?.displayName}>
                <Avatar
                  alt={parent?.displayName}
                  sx={{ width: 26, height: 26 }}
                  src={
                    parent?.displayPicture?.thumbUrl ||
                    parent?.displayPicture?.url
                  }
                />
              </BootstrapTooltip>
            ))}
          </AvatarGroup>
        </Box>
        <HorizBox>
          {task?.labels?.slice(0, 2).map((item) => (
            <div
              style={{
                backgroundColor: item?.color + "33",
                color: item?.color,
                padding: "4px 8px",
                borderRadius: "10px",
              }}
            >
              {item?.title}
            </div>
          ))}{" "}
          {task?.labels?.length > 2 ? (
            <div>+{task?.labels?.length - 2}</div>
          ) : null}
        </HorizBox>
        <Box
          sx={{
            backgroundColor: "#d1d5db",
            p: 1,
            borderRadius: "10px",
            mt: 1,
            position: "relative",
            zIndex: 1,
            height: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: `${task?.progress || 0}%`,
              backgroundColor: "white",
              height: "100%",
              borderRadius: "5px",
              backgroundColor: getProgressColor(task?.progress)
                ?.backgroundColor,
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
            }}
          ></Box>
          <Box
            sx={{
              width: "100%",
              zIndex: 2,
            }}
          >
            <BoxSpaceBetween>
              <HorizBox>
                <Typography
                  variant="h6"
                  sx={{
                    color: getProgressColor(task?.progress)?.color,
                  }}
                >
                  {task?.progress || 0}%
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: getProgressColor(task?.progress)?.color,
                  }}
                >
                  Progress
                </Typography>
              </HorizBox>
            </BoxSpaceBetween>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default BudgetTaskCard;

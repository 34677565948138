import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cloneBudgetCostHead } from "./api.call";
import { Button, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import "./index.css"

const useStyles = makeStyles((theme) => ({
    mainCont:{
        maxHeight: "60vh",
        width: "500px",
        overflowY: "auto"
    },
    amountUnitCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "15px"
    },
    subhedsCont: {
        width: "100%",
        maxHeight: "400px",
        overflowY: "auto",
        margin: "10px 0px 10px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
    },
    tableCont: {
        width: "100%",
        border: "1px solid gray",
        borderCollapse: "collapse",
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px"
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
            textAlign: "right",
            paddingRight: "10px"
        },
        "& .PrivateSwitchBase-root-82": {
            padding: "0px"
        },
        "& .MuiIconButton-root": {
            padding: "0px"
        }
    },
    checkBoxCont: {
        width: "45px"
    },
    workitemCont: {
        width: "75px"
    },
    checkBoxTextCont: {
        display: "flex",
        alignItems: "center",
        "& p": {
            fontSize: "14px",
            fontWeight: "400"
        }
    },
    subheadTitle: {
        fontSize: "15px",
        fontWeight: "500",
        margin: "20px 0px 5px"
    },
    cloneText: {
        fontSize: "15px",
        fontWeight: "400",
        marginTop: "0px 0px 15px",
        "& span": {
            fontWeight: "600"
        }
    },
    bottomBtnCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        },
    }
}));

const CloneProjectHead = ({
    cloneDialogOpen, setCloneDialogOpen, selectedBudgetHead,
    getBudgetCostPopulatedData, cancelSelect, budgetCostId
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [selectableBudgetHead, setSelectableBudgetHead] = useState(null)
    const [isWithQty, setIsWithQty] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let arr = selectedBudgetHead?.items || []
        let updatedArr = []
        arr && arr.map((subhead) => {
            updatedArr.push({
                ...subhead,
                selected: true
            })
        })

        setSelectableBudgetHead({
            ...selectedBudgetHead,
            name: "",
            items: updatedArr,
        })
    }, [selectedBudgetHead])

    const submitClone = async (e) => {
        e.preventDefault();
        setLoading(true)
        let itemsArr = selectableBudgetHead?.items || [];
        let allSelectedSubheadIds = [];
        itemsArr.map((subhead) => {
            if (subhead.selected) {
                allSelectedSubheadIds.push(subhead?._id)
            }
        })
        const budgetHeadNameMap = {};
        budgetHeadNameMap[selectableBudgetHead._id] = selectableBudgetHead?.name || "";
        const locAreaUnitArr = [];
        selectableBudgetHead?.areaUnitArr && selectableBudgetHead.areaUnitArr.map((areaUnit) => {
            locAreaUnitArr.push({
                amount: areaUnit?.amount || 0,
                lableName: areaUnit?.lableName || "",
                unit: areaUnit?.unit || "Sqft"
            })
        })
        const areaUnitAndHeadMap = {};
        areaUnitAndHeadMap[selectableBudgetHead._id] = locAreaUnitArr;

        const reqObj = {
            allSelectedSubheadIds,
            isWithQty,
            userId: user?._id,
            budgetHeadNameMap: budgetHeadNameMap,
            areaUnitAndHeadMap: areaUnitAndHeadMap,
            allBudgetHeadIds: [selectableBudgetHead._id],
            budgetCostId: budgetCostId,
        }

        await cloneBudgetCostHead(reqObj)
            .then(async (data) => {
                await getBudgetCostPopulatedData(false)
                cancelSelect()
                setLoading(false)
                setCloneDialogOpen(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    return (
        <NormalDialog
            openDialog={cloneDialogOpen}
            loading={loading}
            handleCloseShare={() => { setCloneDialogOpen(false) }}
            pageTitle={"Clone Project Head"}
            content={<form onSubmit={submitClone} >
                <div className={classes.mainCont} >
                    <p className={classes.cloneText} >Cloning <span>{selectedBudgetHead?.name}</span></p>
                    <TextField
                        label="Name"
                        value={selectableBudgetHead?.name}
                        size="small"
                        onChange={(e) => {
                            setSelectableBudgetHead({
                                ...selectableBudgetHead,
                                name: e.target.value
                            })
                        }}
                        variant="outlined"
                        placeholder="Enter head name"
                        style={{ marginTop: "10px", width: "100%" }}
                    />

                    {selectableBudgetHead && selectableBudgetHead?.areaUnitArr && selectableBudgetHead?.areaUnitArr.map((areaUnitValue, i) => (
                        <div className={classes.amountUnitCont} key={i} >
                            <TextField
                                label={`${areaUnitValue?.lableName} (optional)`}
                                value={areaUnitValue?.amount}
                                size="small"
                                onChange={(e) => {
                                    let obj = {
                                        ...selectableBudgetHead
                                    }
                                    obj.areaUnitArr[i].amount = e.target.value
                                    setSelectableBudgetHead(obj)
                                }}
                                variant="outlined"
                                placeholder="Enter area value"
                                type="number"
                                style={{ width: "200px" }}
                            />
                            <Autocomplete
                                value={areaUnitValue?.unit || "Sqft"}
                                options={["Sqft", "Sqmt"]}
                                getOptionLabel={(option) => { return option || "" }}
                                getOptionSelected={(option) => { return option === areaUnitValue?.unit }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ margin: "0px" }}
                                        margin="normal"
                                        variant="outlined"
                                        label="Unit"
                                        placeholder={"Select unit"}
                                    />
                                )}
                                onChange={(event, value) => {
                                    let updatedArr = []
                                    selectableBudgetHead?.areaUnitArr && selectableBudgetHead.areaUnitArr.map((areaUnit) => {
                                        updatedArr.push({
                                            ...areaUnit,
                                            unit: value
                                        })
                                    })
                                    setSelectableBudgetHead({
                                        ...selectableBudgetHead,
                                        areaUnitArr: updatedArr,
                                    })
                                }}
                                style={{ width: "130px" }}
                                size="small"
                            />
                        </div>
                    ))}

                    <p className={classes.subheadTitle} >Subheads</p>
                    <div className={classes.subhedsCont} >
                        <table className={classes.tableCont} >
                            <thead>
                                <tr style={{ height: "35px" }} >
                                    <th className={classes.checkBoxCont} ></th>
                                    <th>Name</th>
                                    <th className={classes.workitemCont} >Workitems</th>
                                    <th>Total BudgetCost</th>
                                    <th>Total Estimate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectableBudgetHead?.items && selectableBudgetHead.items.map((subhead, i) => (
                                    <tr key={i} style={{ height: "30px" }} >
                                        <td>
                                            <Checkbox
                                                color="primary"
                                                checked={subhead?.selected}
                                                onChange={(e) => {
                                                    let obj = {
                                                        ...selectableBudgetHead
                                                    }
                                                    obj.items[i].selected = e.target.checked
                                                    setSelectableBudgetHead(obj)
                                                }}
                                            />
                                        </td>
                                        <td style={{ textAlign: "left", paddingLeft: "5px" }} >{subhead?.name}</td>
                                        <td style={{ textAlign: "right" }} >{subhead?.items ? subhead?.items?.length || 0 : 0}</td>
                                        <td style={{ textAlign: "right" }} >{parseFloat(subhead?.totalBudgetCost || 0).toFixed(2)}</td>
                                        <td style={{ textAlign: "right" }} >{(parseFloat(subhead?.totalEstimatedCost || 0) + parseFloat(subhead?.totalEstimatedGST || 0)).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.checkBoxTextCont} >
                        <Checkbox
                            color="primary"
                            checked={isWithQty}
                            onChange={(e) => {
                                setIsWithQty(e.target.checked)
                            }}
                        />
                        <p>Clone along with quantity take off</p>
                    </div>
                </div>
                <div>
                    {loading && (<LinearProgress />)}
                </div>
                <div className={classes.bottomBtnCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >Clone</Button>
                </div>
            </form>}
        />
    );
};

export default CloneProjectHead;
import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute";

import BudgetCostAnalysisEdit from "../components/budgetCostAnalysis/BudgetCostAnalysis.Edit";
import BudgetCostWorkItemEdit from "../components/budgetCostAnalysis/BudgetCostWorkItemEdit";
import BudgetUpload from "../components/budgetCostAnalysis/BudgetUpload";
import AllBudgetCostCont from "../components/budgetCostAnalysis/AllBudgetCostCont"; 
import OfferingsAndProductsManagement from "../components/OfferingsAndProducts/OfferingsAndProductsManagement.js";

const Routes = (props) => {
  return [
    <BasicPrivateRoute
      exact
      path="/budget-analysis/upload/:teamId"
      noAppbar={true}
      component={BudgetUpload}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/budget-analysis/budget-costs/:profileId"
      noAppbar={true}
      useBothSide={true}
      component={AllBudgetCostCont}
    />,
    <BasicPrivateRoute
      exact
      path="/budget-analysis/budget-cost/edit/:budgetCostId"
      noAppbar={true}
      useBothSide={true}
      component={BudgetCostAnalysisEdit}
    />,
    <BasicPrivateRoute
        exact
        path="/budget-analysis/budget-cost/work-items/edit/:subheadId"
        noAppbar={true}
        useBothSide={true}
        component={BudgetCostWorkItemEdit}
    />,
    <BasicPrivateRoute
        exact
        path="/offerings-categories/management/:profileId"
        noAppbar={true}
        useBothSide={true}
        component={OfferingsAndProductsManagement}
    />,
  ];
};

export default Routes;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Button, TextField, Paper } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import { createSubheadByBudegthead, updateHeadForBudgetCostById } from "./api.call";
import BudgetCostSubhead from "./BudgetCostSubhead";
import LinearProgress from '@material-ui/core/LinearProgress';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from "react-icons/io";
import { ScrollSyncPane } from 'react-scroll-sync';
import TextFieldNumberFormated from "../styled/CommonComponents/TextFieldNumberFormated";
import "./index.css"
import { useDebounce } from "react-use";


const useStyles = makeStyles((theme) => ({
    headMainCont: {
        marginBottom: "20px",
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
    },
    BottomMainCont: {
        marginBottom: "40px",
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
    },
    headCont: {
        width: "100%",
        padding: "5px 10px 15px",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "white"
    },
    headerLeft: {
        "& h3": {
            fontSize: "18px",
            fontWeight: "510",
        },
        "& p": {
            fontSize: "13px",
            fontWeight: "400",
            color: "#ececec",
            marginLeft: "20px"
        }
    },
    tableCont: {
        width: "2650px",
        border: "1px solid gray",
        borderCollapse: "collapse",
        tableLayout: "auto",
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px"
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
            textAlign: "right",
            paddingRight: "10px"
        }
    },
    tableContShow: {
        width: "100%",
        height: "auto",
    },
    tableContHide: {
        width: "100%",
        height: "0px",
        overflow: "hidden",
    },
    amountUnitCont: {
        width: "450px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "25px"
    },
    headAndPlusCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiButton-root": {
            minWidth: "24px",
            lineHeight: "0.8",
            marginLeft: "10px"
        }
    },
    checkBoxOtherCont: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "3px"
    },
    addBtn: {
        padding: "5px",
        borderRadius: "50%",
    },
    totalBar: {
        marginTop: "15px",
        "& td": {
            fontSize: "12px",
            fontWeight: "550"
        }
    },
    showChildIcon: {
        transform: "rotate(0deg)",
        transition: "all .3s ease-in-out",
        color: "white"
    },
    hideChildIcon: {
        transform: "rotate(-90deg)",
        transition: "all .3s ease-in-out",
        color: "white"
    },
    width200: {
        width: "200px"
    },
    width150: {
        width: "150px"
    },
    deleteBtn: {
        width: "0px",
        position: "relative",
        "& .MuiIconButton-root": {
            position: "absolute",
            top: "4px",
            left: "5px",
            padding: "5px"
        }
    },
    overflowCont: {
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
    },
    subheadCreateCont: {
        width: "550px"
    },
    twoInputCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
    },
    textFieldSty: {
        width: "48%"
    },
    warningSty: {
        height: "15px",
        fontSize: "9px",
        fontWeight: "400",
        color: "red",
    }
}));

const BudgetCostHead = ({
    costHeadData, costHeads, setCostHeads, mainIndex,
    costingPerVal, projectProfileId, headStateChange,
    setHeadStateChange, expandAllHead, budgetedCostColp,
    estimatedCostColp, columnsObj, costingInVal,
    selectedSubheadIds, setSelectedSubheadIds,
    setDeleteAbleBudgetSubheadIds, setSubheadDeleteOpen,
    setDeleteAbleBudgetHeadIds, setHeadDeleteOpen,
    setSelectableBudgetHead, setCloneDialogOpen,
    setcopyDialogOpen, openApprovedAmountDetails,
    setOpenApprovedAmountDetails, setSelectedSubhead,
    openGrossValueCertifiteDetails, budgetCostId,
    setOpenGrossValueCertifiteDetails, budgetCost,
    openNetCertifitePayableDetails, getBudgetCostPopulatedData,
    setOpenNetCertifitePayableDetails, needToMakeDisable,
    openPaidDetails, setOpenPaidDetails, setTaskOpenData,
    openSubmittedAmountDetails,
    setOpenSubmittedAmountDetails
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [curBudgetCostHead, setCurBudgetCostHead] = useState(null)
    const [openAreaEdit, setOpenAreaEdit] = useState(false)
    const [openAddSubheadEdit, setOpenAddSubheadEdit] = useState(false)
    const [headTitle, setHeadTitle] = useState(costHeadData?.name || "Edit Title")
    const [areaUnitArr, setAreaUnitArr] = useState([])
    const [areaUnitVal, setAreaUnitVal] = useState()
    const [subheadName, setSubheadName] = useState("")
    const [totalBudgetCostC, setTotalBudgetCostC] = useState(0)
    const [totalBudgetCostPerAreaC, setTotalBudgetCostPerAreaC] = useState(0)
    const [submittedAmountC, setSubmittedAmountC] = useState(0)
    const [approvedAmountC, setApprovedAmountC] = useState(0)
    const [grossValueCertifiteC, setGrossValueCertifiteC] = useState(0)
    const [paidC, setPaidC] = useState(0)
    const [headEditLoader, setHeadEditLoader] = useState(false)
    const [selectedCurArea, setSelectedCurArea] = useState(null)
    const [createSubheadLoader, setCreateSubheadLoader] = useState(false)
    const [expandTableBody, setExpandTableBody] = useState(true)
    const [headCheckBox, setHeadCheckBox] = useState(false)

    useEffect(() => {
        setExpandTableBody(expandAllHead)
    }, [expandAllHead])

    //to update all the total values in the whole table
    useEffect(() => {
        let subheads = costHeadData?.items || [];
        let totalBudgetCost = 0;
        let budgetCostPerArea = 0;
        let basicEstimatedCost = 0;
        let totalGst = 0;
        let totalEstimate = 0;
        let costPerAreaWithoutGst = 0;
        let totalLocTotalApprovedAmount = 0
        let totalLocTotalSubmittedAmount = 0
        let totalLocTotalTxInitialAmount = 0
        let totalLocTotalPaidAmount = 0
        let totalLocOutstanding = 0
        let totalLocBudgetBalance = 0
        let totalTaskNumbers = 0;

        let subheadArr = []
        let selectedAreaUnitValue = null
        let locAreaUnitArr = costHeadData?.areaUnitArr || [];
        locAreaUnitArr.map((c) => {
            if (c?.lableName === costingPerVal) {
                selectedAreaUnitValue = c
            }
        })
        let areaVal = 0;
        if (selectedAreaUnitValue?.unit === "Sqft") {
            if (costingInVal?.areaUnit === "Sqft") {
                areaVal = parseFloat(selectedAreaUnitValue?.amount || 1).toFixed(2)
            } else {
                areaVal = parseFloat(parseFloat(selectedAreaUnitValue?.amount || 1) / 10.76391042).toFixed(2)
            }
        } else {
            if (costingInVal?.areaUnit === "Sqft") {
                areaVal = parseFloat(parseFloat(selectedAreaUnitValue?.amount || 1) * 10.76391042).toFixed(2);
            } else {
                areaVal = parseFloat(selectedAreaUnitValue?.amount || 1).toFixed(2)
            }
        }
        setSelectedCurArea({
            amount: areaVal,
            unit: costingInVal?.areaUnit,
            lableName: selectedAreaUnitValue?.lableName
        })

        subheads.map((subheadData) => {
            let locTotalBudgetCost = parseFloat(subheadData?.totalBudgetCost || 0);
            let locBudgetCostPerArea = parseFloat(parseFloat(subheadData?.totalBudgetCost || 0) / areaVal);
            let locBasicEstimatedCost = parseFloat(subheadData?.totalEstimatedCost || 0);
            let locTotalGst = parseFloat(subheadData?.totalEstimatedGST || 0);
            let locTotalEstimate = parseFloat(parseFloat(subheadData?.totalEstimatedCost || 0) + parseFloat(subheadData?.totalEstimatedGST || 0));
            let locCostPerAreaWithGst = (parseFloat(parseFloat(subheadData?.totalEstimatedCost || 0) + parseFloat(subheadData?.totalEstimatedGST || 0)) / areaVal).toFixed(2);
            let locCostPerAreaWithoutGst = parseFloat(parseFloat(subheadData?.totalEstimatedCost || 0) / areaVal);

            let locTotalApprovedAmount = parseFloat(subheadData?.totalApprovedAmount || 0)
            let locTotalSubmittedAmount = parseFloat(subheadData?.totalSubmittedAmount || 0)
            let locTotalTxInitialAmount = parseFloat(subheadData?.totalTxInitialAmount || 0)
            let locTotalPaidAmount = parseFloat(subheadData?.totalPaidAmount || 0)
            let locOutstanding = parseFloat(parseFloat(locTotalTxInitialAmount) - parseFloat(locTotalPaidAmount))
            let locBudgetBalance = parseFloat(locTotalEstimate - locTotalApprovedAmount)

            totalBudgetCost = totalBudgetCost + locTotalBudgetCost;
            budgetCostPerArea = budgetCostPerArea + locBudgetCostPerArea;
            basicEstimatedCost = basicEstimatedCost + locBasicEstimatedCost
            totalGst = totalGst + locTotalGst
            totalEstimate = totalEstimate + locTotalEstimate
            costPerAreaWithoutGst = costPerAreaWithoutGst + locCostPerAreaWithoutGst
            totalLocTotalApprovedAmount = totalLocTotalApprovedAmount + locTotalApprovedAmount
            totalLocTotalSubmittedAmount = totalLocTotalSubmittedAmount + locTotalSubmittedAmount
            totalLocTotalTxInitialAmount = totalLocTotalTxInitialAmount + locTotalTxInitialAmount
            totalLocTotalPaidAmount = totalLocTotalPaidAmount + locTotalPaidAmount
            totalLocOutstanding = totalLocOutstanding + locOutstanding
            totalLocBudgetBalance = totalLocBudgetBalance + locBudgetBalance
            totalTaskNumbers = totalTaskNumbers + parseInt(subheadData?.numberOfTasks || 0)
            subheadArr.push({
                _id: subheadData?._id,
                name: subheadData?.name || "",
                numberOfTasks: subheadData?.numberOfTasks,
                totalBudgetCost: locTotalBudgetCost,
                budgetCostPerArea: locBudgetCostPerArea,
                basicEstimatedCost: locBasicEstimatedCost,
                totalGst: locTotalGst,
                linkedItems : subheadData?.subheadBudgetmappings,
                totalApprovedAmount: locTotalApprovedAmount,
                totalSubmittedAmount: locTotalSubmittedAmount,
                totalTxInitialAmount: locTotalTxInitialAmount,
                totalPaidAmount: locTotalPaidAmount,
                outstanding: locOutstanding,
                budgetBalance: locBudgetBalance,
                totalEstimate: locTotalEstimate,
                costPerAreaWithGst: locCostPerAreaWithGst,
                costPerAreaWithoutGst: locCostPerAreaWithoutGst,
                variance: parseFloat(parseFloat(subheadData?.totalBudgetCost || 0) - parseFloat(parseFloat(subheadData?.totalEstimatedCost || 0) + parseFloat(subheadData?.totalEstimatedGST || 0))),
            })
        })
        const finalHeadObj = {
            name: costHeadData?.name || "",
            selectedArea: selectedAreaUnitValue || null,
            subheads: subheadArr,
            totalBudgetCost: totalBudgetCost,
            budgetCostPerArea: budgetCostPerArea,
            basicEstimatedCost: basicEstimatedCost,
            totalGst: totalGst,
            totalTaskNumbers: totalTaskNumbers,
            totalLocTotalApprovedAmount,
            totalLocTotalSubmittedAmount,
            totalLocTotalTxInitialAmount,
            totalLocTotalPaidAmount,
            totalLocOutstanding,
            totalLocBudgetBalance,
            totalEstimate: totalEstimate,
            costPerAreaWithGst: (parseFloat(totalEstimate / areaVal)).toFixed(2),
            costPerAreaWithoutGst: costPerAreaWithoutGst,
            variance: parseFloat(totalBudgetCost - totalEstimate),
        }
        setCurBudgetCostHead(finalHeadObj)
        setAreaUnitArr(locAreaUnitArr)
        setAreaUnitVal(locAreaUnitArr[0]?.unit)
    }, [costHeadData, costingPerVal, costHeads, headStateChange, costingInVal])

    //edit head submit to update head
    const editHeadSubmit = async (e) => {
        e.preventDefault();
        setHeadEditLoader(true)
        let arr = []
        areaUnitArr.map((areaUnitData) => {
            arr.push({
                ...areaUnitData,
                unit: areaUnitVal
            })
        });

        await updateHeadForBudgetCostById({
            name: headTitle,
            areaUnitArr: arr,
            _id: costHeadData?._id
        })
            .then((data) => {
                let locCostHeads = [...costHeads]
                locCostHeads[mainIndex] = data
                setCostHeads(locCostHeads)
                setOpenAreaEdit(false)
                setHeadStateChange(!headStateChange)
            })
            .catch((err) => {
                console.log(err)
            })
        setHeadEditLoader(false)
    }

    //add new subhead 
    const addSubheadIteam = async (e) => {
        e.preventDefault();
        setCreateSubheadLoader(true)
        await createSubheadByBudegthead({
            projectProfileId: projectProfileId,
            userId: user?._id,
            name: subheadName,
            budgetCostId: budgetCostId,
            budgetCostHeadId: costHeadData?._id,
            totalBudgetCost: totalBudgetCostC,
            submittedAmount: submittedAmountC,
            approvedAmount: approvedAmountC,
            grossValueCertifite: grossValueCertifiteC,
            paid: paidC,
            projectBlock: budgetCost?.projectBlock,
            project: budgetCost?.project,
        })
            .then(async (data) => {
                await getBudgetCostPopulatedData(false)
                setOpenAddSubheadEdit(false)
                setHeadStateChange(!headStateChange)
            })
            .catch((err) => {
                console.log(err)
            })
        setCreateSubheadLoader(false)
        setExpandTableBody(true)
    }

    //function to add coma with the number input field
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        let arr = curBudgetCostHead?.subheads || []
        let val = true;
        arr.map((ar) => {
            if (!selectedSubheadIds.includes(ar._id)) {
                val = false;
            }
        })
        if (arr.length > 0) {
            setHeadCheckBox(val)
        } else {
            setHeadCheckBox(false)
        }
    }, [selectedSubheadIds])

    const onHeaderCheckClick = (val) => {
        setHeadCheckBox(val)
        let arr = curBudgetCostHead?.subheads || []
        let idsArr = [];
        arr.map((ar) => { idsArr.push(ar?._id) })

        if (val) {
            let arr = [...selectedSubheadIds, ...idsArr]
            const set = new Set(arr);
            setSelectedSubheadIds([...set])
        } else {
            let ansArr = []
            selectedSubheadIds.map((id) => {
                if (!idsArr.includes(id)) {
                    ansArr.push(id);
                }
            })
            setSelectedSubheadIds(ansArr)
        }
    }

    useDebounce(() => {
        if (totalBudgetCostC !== "" && totalBudgetCostC !== null) {
            setTotalBudgetCostPerAreaC((parseFloat(totalBudgetCostC || 0) / parseFloat(selectedCurArea?.amount || 1)).toFixed(2))
        }
    }, 1000, [totalBudgetCostC])

    useDebounce(() => {
        if (totalBudgetCostPerAreaC !== "" && totalBudgetCostPerAreaC !== null) {
            setTotalBudgetCostC(parseFloat(parseFloat(totalBudgetCostPerAreaC || 0) * parseFloat(selectedCurArea?.amount || 1)).toFixed(0))
        }
    }, 1000, [totalBudgetCostPerAreaC])

    return (<>
        <Paper elevation={1} className={classes.headMainCont} >
            <div className={classes.headCont} >
                {/* blue head left side which contain name, edit and collapse or expand */}
                <div className={classes.headerLeft} >
                    <div style={{ display: "flex", alignItems: "center" }} >
                        <IconButton onClick={() => { setExpandTableBody(!expandTableBody) }} >
                            <ExpandMoreIcon className={expandTableBody ? classes.showChildIcon : classes.hideChildIcon} />
                        </IconButton>
                        <h3>{`Project Head - ${curBudgetCostHead?.name}`}</h3>
                        {!needToMakeDisable && (<>
                            <IconButton
                                onClick={() => { setOpenAreaEdit(true) }}
                                disabled={headEditLoader}
                            >
                                <EditIcon style={{ color: "white" }} />
                            </IconButton>
                            <NormalDialog
                                openDialog={openAreaEdit}
                                loading={headEditLoader}
                                handleCloseShare={() => { setOpenAreaEdit(false) }}
                                pageTitle={"Edit project head"}
                                content={<form onSubmit={editHeadSubmit} >
                                    <div>
                                        <TextField
                                            label="Name"
                                            value={headTitle}
                                            size="small"
                                            onChange={(e) => { setHeadTitle(e.target.value); }}
                                            variant="outlined"
                                            placeholder="Enter head name"
                                            style={{ marginBottom: "20px", marginTop: "10px", width: "100%" }}
                                        />
                                        {areaUnitArr && areaUnitArr.map((differentAreaTypeData, i) => (<div key={i} className={classes.amountUnitCont} >
                                            <TextField
                                                label={`${differentAreaTypeData?.lableName} (optional)`}
                                                value={differentAreaTypeData?.amount}
                                                size="small"
                                                onChange={(e) => {
                                                    let arr = [...areaUnitArr]
                                                    arr[i].amount = e.target.value
                                                    setAreaUnitArr(arr)
                                                }}
                                                variant="outlined"
                                                placeholder="Enter area value"
                                                type="number"
                                                style={{ width: "200px" }}
                                            />
                                            <Autocomplete
                                                value={areaUnitVal}
                                                options={["Sqft", "Sqmt"]}
                                                getOptionLabel={(option) => { return option || "" }}
                                                getOptionSelected={(option) => { return option === areaUnitVal }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        style={{ margin: "0px" }}
                                                        margin="normal"
                                                        variant="outlined"
                                                        label="Unit"
                                                        placeholder={"Select unit"}
                                                    />
                                                )}
                                                onChange={(event, value) => {
                                                    setAreaUnitVal(value)
                                                }}
                                                style={{ width: "130px" }}
                                                size="small"
                                            />
                                        </div>))}
                                    </div>
                                    <div style={{ width: "100%", height: "4px" }} >
                                        {headEditLoader && (<LinearProgress />)}
                                    </div>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "15px" }} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            size="small"
                                            disabled={headEditLoader}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </form>}
                            />
                        </>)}
                    </div>
                    <p>{`Total ${selectedCurArea?.lableName} : ${numberWithCommas(parseFloat(selectedCurArea?.amount))} ${selectedCurArea?.unit}`}</p>
                </div>
                {/* blue head right side which contain clone, print and delete  */}
                <div style={{ display: "flex", alignItems: "center" }} >
                    {!needToMakeDisable && (
                        <IconButton
                            onClick={() => {
                                setSelectableBudgetHead(costHeadData)
                                setCloneDialogOpen(true)
                            }}
                        >
                            <FileCopyIcon style={{ color: "white" }} />
                        </IconButton>
                    )}
                    {!needToMakeDisable && (
                        <IconButton disabled >
                        <PrintIcon style={{ color: "white" }} />
                    </IconButton>
                    )}
                    {!needToMakeDisable && (
                        <IconButton
                            onClick={() => {
                                const headId = costHeadData?._id;
                                setDeleteAbleBudgetHeadIds([headId])
                                setHeadDeleteOpen(true)
                            }}
                        >
                            <DeleteIcon style={{ color: "white" }} />
                        </IconButton>
                    )}
                </div>
            </div>

            {/* Data table header part only */}
            <ScrollSyncPane>
                <div className={classes.overflowCont} >
                    <table className={classes.tableCont} style={{ marginBottom: "3px" }} >
                        <thead>
                            <tr>
                                <th rowspan="2" className={classes.width200} >
                                    <div className={classes.checkBoxOtherCont} >
                                        {!needToMakeDisable && (
                                            <Checkbox
                                                color="primary"
                                                checked={headCheckBox}
                                                onChange={(e) => {
                                                    let bool = headCheckBox ? false : true;
                                                    onHeaderCheckClick(bool)
                                                }}
                                            />
                                        )}
                                        <div className={classes.headAndPlusCont} >
                                            <p>Cost Head</p>
                                            {!needToMakeDisable && (<>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.addBtn}
                                                    onClick={() => { setOpenAddSubheadEdit(true) }}
                                                    disabled={createSubheadLoader}
                                                >+</Button>
                                                <NormalDialog
                                                    openDialog={openAddSubheadEdit}
                                                    handleCloseShare={() => { setOpenAddSubheadEdit(false) }}
                                                    pageTitle={"Add subhead"}
                                                    loading={createSubheadLoader}
                                                    content={<form onSubmit={addSubheadIteam} className={classes.subheadCreateCont} >
                                                        <TextField
                                                            label="Name"
                                                            value={subheadName}
                                                            size="small"
                                                            onChange={(e) => { setSubheadName(e.target.value); }}
                                                            variant="outlined"
                                                            placeholder="Enter subhead name"
                                                            style={{ marginBottom: "30px", marginTop: "10px", width: "100%" }}
                                                        />
                                                        <div className={classes.twoInputCont} style={{ marginBottom: "35px" }} >
                                                            <div className={classes.textFieldSty} >
                                                                <TextFieldNumberFormated
                                                                    label="Total Budget Cost"
                                                                    value={totalBudgetCostC}
                                                                    size="small"
                                                                    onChange={(e) => {
                                                                        setTotalBudgetCostC(e.target.value);
                                                                    }}
                                                                    variant="outlined"
                                                                    placeholder="Enter amount"
                                                                    type="number"
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </div>
                                                            <div className={classes.textFieldSty} >
                                                                <TextFieldNumberFormated
                                                                    label="Total Budget Cost Per Area"
                                                                    value={totalBudgetCostPerAreaC}
                                                                    size="small"
                                                                    onChange={(e) => {
                                                                        setTotalBudgetCostPerAreaC(e.target.value);
                                                                    }}
                                                                    variant="outlined"
                                                                    placeholder="Enter amount"
                                                                    type="number"
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={classes.twoInputCont} >
                                                            <div className={classes.textFieldSty} >
                                                                <TextFieldNumberFormated
                                                                    label="Submitted Amount"
                                                                    value={submittedAmountC}
                                                                    size="small"
                                                                    onChange={(e) => { setSubmittedAmountC(e.target.value); }}
                                                                    variant="outlined"
                                                                    placeholder="Enter amount"
                                                                    type="number"
                                                                    style={{ width: "100%" }}
                                                                />
                                                                <div className={classes.warningSty} >
                                                                    {/* {approvedAmountC !== submittedAmountC ? "Approved amount should be equal to submitted amount" : ""} */}
                                                                </div>
                                                            </div>
                                                            <div className={classes.textFieldSty} >
                                                                <TextFieldNumberFormated
                                                                    label="Approved Amount"
                                                                    value={approvedAmountC}
                                                                    size="small"
                                                                    onChange={(e) => { setApprovedAmountC(e.target.value); }}
                                                                    variant="outlined"
                                                                    placeholder="Enter amount"
                                                                    type="number"
                                                                    style={{ width: "100%" }}
                                                                />
                                                                <div className={classes.warningSty} >
                                                                    {parseFloat(approvedAmountC || 0) > parseFloat(submittedAmountC || 0) ? "Approved amount should be less or equal to submitted amount" : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={classes.twoInputCont} >
                                                            <div className={classes.textFieldSty} >
                                                                <TextFieldNumberFormated
                                                                    label="Gross Value Certifite"
                                                                    value={grossValueCertifiteC}
                                                                    size="small"
                                                                    onChange={(e) => { setGrossValueCertifiteC(e.target.value); }}
                                                                    variant="outlined"
                                                                    placeholder="Enter amount"
                                                                    type="number"
                                                                    style={{ width: "100%" }}
                                                                />
                                                                <div className={classes.warningSty} >
                                                                    {parseFloat(grossValueCertifiteC || 0) > parseFloat(approvedAmountC || 0) ? "Gross value certifite should be less or equal to approved amount" : ""}
                                                                </div>
                                                            </div>
                                                            <div className={classes.textFieldSty} >
                                                                <TextFieldNumberFormated
                                                                    label="Paid"
                                                                    value={paidC}
                                                                    size="small"
                                                                    onChange={(e) => { setPaidC(e.target.value); }}
                                                                    variant="outlined"
                                                                    placeholder="Enter paid amount"
                                                                    type="number"
                                                                    style={{ width: "100%" }}
                                                                />
                                                                <div className={classes.warningSty} >
                                                                    {parseFloat(paidC || 0) > parseFloat(grossValueCertifiteC || 0) ? "Paid amount should be less or equal to gross value certifite" : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "100%", height: "4px" }} >
                                                            {createSubheadLoader && (<LinearProgress />)}
                                                        </div>
                                                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                size="small"
                                                                disabled={subheadName && subheadName.length > 1 && parseFloat(submittedAmountC) >= parseFloat(approvedAmountC) && parseFloat(approvedAmountC) >= parseFloat(grossValueCertifiteC) && parseFloat(grossValueCertifiteC) >= parseFloat(paidC) ? createSubheadLoader ? true : false : true}
                                                            >
                                                                Add
                                                            </Button>
                                                        </div>
                                                    </form>}
                                                />
                                            </>)}
                                        </div>
                                    </div>
                                </th>
                                <th rowspan="2" className={classes.width200} >Linked Items</th>
                                <th colspan="2" >Budgeted Cost</th>
                                <th colspan="3" >Allocated</th>
                                <th colspan="2" >Contracted</th>
                                <th colspan="3" >Payments</th>
                                <th rowspan="2" className={classes.width200} >Budget Balance </th>
                                <th rowspan="2" className={classes.width200} >Cost Variance</th>
                            </tr>
                            <tr>
                                <th className={classes.width200} >Total budgeted cost</th>
                                <th className={classes.width150} >Cost per area</th>
                                <th className={classes.width200} >Base Amount</th>
                                <th className={classes.width150} >Total Tax</th>
                                <th className={classes.width200} >Gross Amount</th>
                                <th className={classes.width200} >Submitted Amount</th>
                                <th className={classes.width200} >Approved Amount</th>
                                <th className={classes.width200} >Gross Value Certifite</th>
                                <th className={classes.width200} >Paid</th>
                                <th className={classes.width200} >Outstanding</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </ScrollSyncPane>

            {/* Data table body part only */}
            <ScrollSyncPane>
                <div className={classes.overflowCont} >
                    <Collapse in={expandTableBody}>
                        <table className={classes.tableCont} >
                            <thead>
                                <tr style={{ visibility: "collapse" }} >
                                    <th rowspan="2" className={classes.width200} >
                                        <div className={classes.checkBoxOtherCont} >
                                            {!needToMakeDisable && (
                                                <Checkbox
                                                    color="primary"
                                                    checked={headCheckBox}
                                                    onChange={(e) => {
                                                        let bool = headCheckBox ? false : true;
                                                        onHeaderCheckClick(bool)
                                                    }}
                                                />
                                            )}
                                            <div className={classes.headAndPlusCont} >
                                                <p>Cost Head</p>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.addBtn}
                                                    onClick={() => { }}
                                                    disabled
                                                >+</Button>
                                            </div>
                                        </div>
                                    </th>
                                    <th rowspan="2" >Linked Items</th>
                                    <th colspan="2" >Budgeted Cost</th>
                                    <th colspan="3" >Allocated</th>
                                    <th colspan="2" >Contracted</th>
                                    <th colspan="3" >Payments</th>
                                    <th rowspan="2" className={classes.width200} >Budget Balance </th>
                                    <th rowspan="2" className={classes.width200} >Cost Variance</th>
                                </tr>
                                <tr style={{ visibility: "collapse" }} >
                                    <th className={classes.width200} >Total budgeted cost</th>
                                    <th className={classes.width150} >Cost per area</th>
                                    <th className={classes.width200} >Base Amount</th>
                                    <th className={classes.width150} >Total Tax</th>
                                    <th className={classes.width200} >Gross Amount</th>
                                    <th className={classes.width200} >Submitted Amount</th>
                                    <th className={classes.width200} >Approved Amount</th>
                                    <th className={classes.width200} >Gross Value Certifite</th>
                                    <th className={classes.width200} >Paid</th>
                                    <th className={classes.width200} >Outstanding</th>
                                </tr>
                            </thead>
                            <tbody>
                                {curBudgetCostHead && curBudgetCostHead?.subheads && curBudgetCostHead.subheads.map((subheadData, index) => (
                                    <BudgetCostSubhead
                                        key={index}
                                        needToMakeDisable={needToMakeDisable}
                                        costHeads={costHeads}
                                        setCostHeads={setCostHeads}
                                        mainIndex={mainIndex}
                                        selectedCurArea={selectedCurArea}
                                        subheadData={subheadData}
                                        index={index}
                                        headStateChange={headStateChange}
                                        setHeadStateChange={setHeadStateChange}
                                        columnsObj={columnsObj}
                                        selectedSubheadIds={selectedSubheadIds}
                                        setSelectedSubheadIds={setSelectedSubheadIds}
                                        setDeleteAbleBudgetSubheadIds={setDeleteAbleBudgetSubheadIds}
                                        setSubheadDeleteOpen={setSubheadDeleteOpen}
                                        setcopyDialogOpen={setcopyDialogOpen}
                                        openApprovedAmountDetails={openApprovedAmountDetails}
                                        setOpenApprovedAmountDetails={setOpenApprovedAmountDetails}
                                        openGrossValueCertifiteDetails={openGrossValueCertifiteDetails}
                                        setOpenGrossValueCertifiteDetails={setOpenGrossValueCertifiteDetails}
                                        openNetCertifitePayableDetails={openNetCertifitePayableDetails}
                                        setOpenNetCertifitePayableDetails={setOpenNetCertifitePayableDetails}
                                        openPaidDetails={openPaidDetails}
                                        setOpenPaidDetails={setOpenPaidDetails}
                                        openSubmittedAmountDetails={openSubmittedAmountDetails}
                                        setOpenSubmittedAmountDetails={setOpenSubmittedAmountDetails}
                                        setSelectedSubhead={setSelectedSubhead}
                                        setTaskOpenData={setTaskOpenData}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </div>
            </ScrollSyncPane>
        </Paper>
        {/* Total of all data */}
        <ScrollSyncPane>
            <Paper elevation={1} className={classes.BottomMainCont} >
                <table className={classes.tableCont} >
                    <tbody>
                        <tr className={classes.totalBar} >
                            <td className={classes.width200} style={{ textAlign: "left", paddingLeft: "10px" }} >Total</td>
                            <td className={classes.width200} >{numberWithCommas(curBudgetCostHead?.totalTaskNumbers || 0)}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalBudgetCost || 0).toFixed(2))}</td>
                            <td className={classes.width150} >{numberWithCommas(parseFloat(curBudgetCostHead?.budgetCostPerArea || 0).toFixed(2))}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.basicEstimatedCost || 0).toFixed(2))}</td>
                            <td className={classes.width150} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalGst || 0).toFixed(2))}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalEstimate || 0).toFixed(2))}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalLocTotalSubmittedAmount || 0).toFixed(2))}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalLocTotalApprovedAmount || 0).toFixed(2))}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalLocTotalTxInitialAmount || 0).toFixed(2))}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalLocTotalPaidAmount || 0).toFixed(2))}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalLocOutstanding || 0).toFixed(2))}</td>
                            <td className={classes.width200} >{numberWithCommas(parseFloat(curBudgetCostHead?.totalLocBudgetBalance || 0).toFixed(2))}</td>
                            <td className={classes.width200} >
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
                                    <p>{numberWithCommas(Math.abs(parseFloat(curBudgetCostHead?.variance || 0).toFixed(2)))}</p>
                                    {parseFloat(curBudgetCostHead?.variance || 0) > 0 ?
                                        (<IoIosArrowRoundDown style={{ color: "green", fontSize: "20px" }} />)
                                        : parseFloat(curBudgetCostHead?.variance || 0) === 0 ? null
                                            : (<IoIosArrowRoundUp style={{ color: "red", fontSize: "20px" }} />)}
                                </div>
                            </td>
                            {/* <td className={classes.deleteBtn} style={{ padding: "0px" }} ></td> */}
                        </tr>
                    </tbody>
                </table>
            </Paper>
        </ScrollSyncPane>
    </>);
};

export default BudgetCostHead;
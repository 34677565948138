import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSubheadById } from "./api.call";
import { useDebounce } from 'react-use';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from "react-icons/io";
import TextFieldNumberWithoutAnyStyle from "../styled/CommonComponents/TextFieldNumberWithoutAnyStyle";
import { GoTasklist } from "react-icons/go";
import Tooltip from '@material-ui/core/Tooltip';
import "./index.css"
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    subHeadTitleSty: {
        width: "200px"
    },
    deleteBtn: {
        width: "0px",
        position: "relative",
        "& .MuiIconButton-root": {
            position: "absolute",
            top: "4px",
            left: "5px",
            padding: "5px"
        }
    },
    classStyle: {
        "& input": {
            textAlign: "right",
            fontSize: "12px",
            fontWeight: "500",
            paddingRight: "5px"
        }
    },
    optionCont: {
        padding: "5px",
        "& .MuiSvgIcon-root": {
            fontSize: "19px"
        },
    },
    singleOption: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 10px",
        paddingRight: "20px",
        cursor: "pointer",
        "& p": {
            marginLeft: "5px"
        },
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    width200: {
        width: "200px"
    },
    width150: {
        width: "150px"
    },
    clickAble: {
        textDecoration: "underline",
        color: "blue",
        cursor: "pointer",
    },
    linkedItemsCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        "& .MuiIconButton-root": {
            padding: "10px"
        }
    },
    iconSty: {
        fontSize: "25px",
        color: "white",
        borderRadius: "12px",
        padding: "3px"
    }
}));

const BudgetCostSubhead = ({
    costHeads, setCostHeads, mainIndex, selectedCurArea,
    subheadData, index, headStateChange, setHeadStateChange,
    columnsObj, selectedSubheadIds, setSelectedSubheadIds,
    setDeleteAbleBudgetSubheadIds, setSubheadDeleteOpen,
    setcopyDialogOpen, openApprovedAmountDetails,
    setOpenApprovedAmountDetails, setSelectedSubhead,
    openGrossValueCertifiteDetails, needToMakeDisable,
    setOpenGrossValueCertifiteDetails, setTaskOpenData,
    openNetCertifitePayableDetails,
    setOpenNetCertifitePayableDetails,
    openPaidDetails, setOpenPaidDetails,
    openSubmittedAmountDetails,
    setOpenSubmittedAmountDetails
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const [name, setName] = useState(subheadData?.name || "")
    const [budgetValue, setBudgetValue] = useState(subheadData?.totalBudgetCost || 0)
    const [budgetValuePer, setBudgetValuePer] = useState(subheadData?.budgetCostPerArea || 0)
    const [initialStoper, setInitialStoper] = useState(false)

    //to set initial values to the useState
    useEffect(() => {
        setName(subheadData?.name || "")
        setBudgetValue((subheadData?.totalBudgetCost || 0).toFixed(2))
        setBudgetValuePer((subheadData?.budgetCostPerArea || 0).toFixed(2))
    }, [subheadData])

    //to redirect subhead details page
    const editSubheadItem = () => {
        if (!needToMakeDisable && costHeads[mainIndex]?.items[index]?._id) {
            let urlGo = `/budget-analysis/budget-cost/work-items/edit/${costHeads[mainIndex]?.items[index]?._id}`
            history.push(urlGo)
        }
    }

    //to update name and totalBudgetCost, for costHeads useState to show all the calculated value
    useDebounce(() => {
        let updatedCostHeads = [...costHeads]
        let locItems = costHeads[mainIndex]?.items
        let updatedItems = [...locItems]
        updatedItems[index].name = name
        updatedItems[index].totalBudgetCost = budgetValue
        updatedCostHeads[mainIndex].items = updatedItems;
        setCostHeads(updatedCostHeads)
        setHeadStateChange(!headStateChange)
    }, 1500, [name, budgetValue])

    //if user press enter button after giving the input it will get call to update name
    const onNameSubmit = async (e) => {
        e.preventDefault();
        await updateSubheadById({
            _id: costHeads[mainIndex]?.items[index]?._id,
            name: name,
            totalBudgetCost: budgetValue
        })
            .then((data) => {
                console.log("data")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //if user press enter button after giving the input it will get call to update totalBudgetCost
    const onAmountSubmit = async (e) => {
        e.preventDefault();
        await updateSubheadById({
            _id: costHeads[mainIndex]?.items[index]?._id,
            name: name,
            totalBudgetCost: budgetValue
        })
            .then((data) => {
                console.log("data")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //if user press enter button after giving the input it will get call to update totalBudgetCost
    const onPerAmountSubmit = async (e) => {
        e.preventDefault();
        await updateSubheadById({
            _id: costHeads[mainIndex]?.items[index]?._id,
            name: name,
            totalBudgetCost: budgetValue
        })
            .then((data) => {
                console.log("data")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //to update name & totalBudgetCost from useDebounce
    useDebounce(() => {
        if (initialStoper) {
            updateSubheadById({
                _id: costHeads[mainIndex]?.items[index]?._id,
                name: name,
                totalBudgetCost: budgetValue
            })
                .then((data) => {
                    console.log("data")
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setInitialStoper(true)
        }
    }, 2000, [name, budgetValue, budgetValuePer]);

    useDebounce(() => {
        setBudgetValuePer(parseFloat(parseFloat(budgetValue || 0) / parseFloat(selectedCurArea?.amount || 1)).toFixed(2))
    }, 1000, [budgetValue])

    useDebounce(() => {
        setBudgetValue(parseFloat(parseFloat(budgetValuePer || 0) * parseFloat(selectedCurArea?.amount || 1)).toFixed(2))
    }, 1000, [budgetValuePer])

    //method to add coma with number
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const openDetailsDialog = (type) => {
        setSelectedSubhead(subheadData);
        setOpenSubmittedAmountDetails(false);
        setOpenApprovedAmountDetails(false);
        setOpenGrossValueCertifiteDetails(false)
        setOpenNetCertifitePayableDetails(false)
        setOpenPaidDetails(false)

        switch (type) {
            case "submittedAmount":
                setOpenSubmittedAmountDetails(true);
                break;
            case "approvedAmount":
                setOpenApprovedAmountDetails(true);
                break;
            case "grossValueCertifite":
                setOpenGrossValueCertifiteDetails(true)
                break;
            case "netCertifitePayable":
                setOpenNetCertifitePayableDetails(true)
                break;
            case "paid":
                setOpenPaidDetails(true)
                break;
        }
    }

    const onOpenTaskDetails = (curSubheadData) => {
        console.log(curSubheadData)
        let taskIds = []
        curSubheadData?.linkedItems && curSubheadData.linkedItems.map((linkedItem) => {
            if (linkedItem?.dataModel === "WBS") {
                let locIds = linkedItem?.data || []
                taskIds = [...taskIds, ...locIds]
            }
        })
        setTaskOpenData({
            open: true,
            taskIds: taskIds
        })
    }

    return (<tr className="subheadStyle" >
        <td style={{ position: "relative", padding: "0px" }} className={classes.width200} >
            <EditIcon className="editContIcon" />
            <form style={{ width: "100%", padding: "2px", display: "flex", alignItems: "center", justifyContent: "space-between" }} onSubmit={onNameSubmit} >
                {!needToMakeDisable && (
                    <Checkbox
                        color="primary"
                        checked={selectedSubheadIds.includes(subheadData?._id)}
                        onChange={(e) => {
                            if (selectedSubheadIds.includes(subheadData?._id)) {
                                setSelectedSubheadIds(selectedSubheadIds.filter(id => id !== subheadData?._id))
                            } else {
                                setSelectedSubheadIds([...selectedSubheadIds, subheadData?._id])
                            }
                        }}
                    />
                )}
                <input
                    type="text"
                    value={name}
                    disabled={needToMakeDisable}
                    style={name && name.length > 0 ? { textAlign: "left", width: "calc(100% - 40px)" } : { border: "1px solid red", textAlign: "left", width: "calc(100% - 40px)" }}
                    onChange={(e) => { setName(e.target.value) }}
                />
            </form>
        </td>
        <td className={classes.width200} >
            <div className={classes.linkedItemsCont} >
                <Tooltip title={`Tasks-${subheadData?.numberOfTasks}`} placement="top">
                    <IconButton onClick={() => { onOpenTaskDetails(subheadData) }} >
                        <GoTasklist
                            className={classes.iconSty}
                            style={{ backgroundColor: "green" }}
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </td>
        <td className={classes.width200} style={{ padding: "0px", position: "relative" }} >
            <EditIcon className="editContIcon" />
            <form style={{ width: "100%", padding: "2px" }} onSubmit={onAmountSubmit} >
                {needToMakeDisable ? (
                    <TextFieldNumberWithoutAnyStyle
                        value={budgetValue}
                        classStyle={classes.classStyle}
                        disabled
                    />
                ) : (
                    <TextFieldNumberWithoutAnyStyle
                        value={budgetValue}
                        classStyle={classes.classStyle}
                        onChange={(e) => { setBudgetValue(e.target.value) }}
                    />
                )}
            </form>
        </td>
        <td className={classes.width150} style={{ padding: "0px", position: "relative" }} >
            <EditIcon className="editContIcon" />
            <form style={{ width: "100%", padding: "2px" }} onSubmit={onPerAmountSubmit} >
                {needToMakeDisable ? (
                    <TextFieldNumberWithoutAnyStyle
                        value={budgetValuePer}
                        classStyle={classes.classStyle}
                        disabled
                    />
                ) : (
                    <TextFieldNumberWithoutAnyStyle
                        value={budgetValuePer}
                        classStyle={classes.classStyle}
                        onChange={(e) => { setBudgetValuePer(e.target.value) }}
                    />
                )}
            </form>
        </td>
        <td className={classes.width200} style={needToMakeDisable ? {} : { cursor: "pointer" }} onClick={editSubheadItem} >{numberWithCommas(parseFloat(subheadData?.basicEstimatedCost || 0).toFixed(2))}</td>
        <td className={classes.width150} style={needToMakeDisable ? {} : { cursor: "pointer" }} onClick={editSubheadItem} >{numberWithCommas(parseFloat(subheadData?.totalGst || 0).toFixed(2))}</td>
        <td className={classes.width200} style={needToMakeDisable ? {} : { cursor: "pointer" }} onClick={editSubheadItem} >{numberWithCommas(parseFloat(subheadData?.totalEstimate || 0).toFixed(2))}</td>
        <td className={`${classes.width200} ${classes.clickAble}`} onClick={() => { openDetailsDialog("submittedAmount") }} >{numberWithCommas(parseFloat(subheadData?.totalSubmittedAmount || 0).toFixed(2))}</td>
        <td className={`${classes.width200} ${classes.clickAble}`} onClick={() => { openDetailsDialog("approvedAmount") }} >{numberWithCommas(parseFloat(subheadData?.totalApprovedAmount || 0).toFixed(2))}</td>
        <td className={`${classes.width200} ${classes.clickAble}`} onClick={() => { openDetailsDialog("grossValueCertifite") }} >{numberWithCommas(parseFloat(subheadData?.totalTxInitialAmount || 0).toFixed(2))}</td>
        <td className={`${classes.width200} ${classes.clickAble}`} onClick={() => { openDetailsDialog("paid") }} >{numberWithCommas(parseFloat(subheadData?.totalPaidAmount || 0).toFixed(2))}</td>
        <td className={classes.width200} style={needToMakeDisable ? {} : { cursor: "pointer" }} onClick={editSubheadItem} >{numberWithCommas(parseFloat(subheadData?.outstanding || 0).toFixed(2))}</td>
        <td className={classes.width200} >{numberWithCommas(parseFloat(subheadData?.budgetBalance || 0).toFixed(2))}</td>
        <td className={classes.width200} style={needToMakeDisable ? {} : { cursor: "pointer" }} onClick={editSubheadItem} >
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
                <p>{numberWithCommas(Math.abs(parseFloat(subheadData?.variance || 0).toFixed(2)))}</p>
                {parseFloat(subheadData?.variance || 0) > 0 ?
                    (<IoIosArrowRoundDown style={{ color: "green", fontSize: "20px" }} />)
                    : parseFloat(subheadData?.variance || 0) === 0 ? null
                        : (<IoIosArrowRoundUp style={{ color: "red", fontSize: "20px" }} />)}
            </div>
        </td>
        {/* <td className={classes.deleteBtn} style={{ padding: "0px" }} >
            <MyPopOver
                closeOnClick={true}
                appearContent={
                    <IconButton>
                        <MoreVertIcon />
                    </IconButton>
                }
                showContent={<div className={classes.optionCont} >
                    <div
                        className={classes.singleOption}
                        onClick={() => {
                            setDeleteAbleBudgetSubheadIds([subheadData?._id])
                            setSubheadDeleteOpen(true)
                        }}
                    >
                        <DeleteIcon />
                        <p>Delete</p>
                    </div>
                    <div
                        className={classes.singleOption}
                        onClick={() => {
                            if (selectedSubheadIds && selectedSubheadIds.length > 0) {
                                if (selectedSubheadIds.includes(subheadData?._id)) {
                                    setSelectedSubheadIds(selectedSubheadIds.filter(id => id !== subheadData?._id))
                                } else {
                                    setSelectedSubheadIds([...selectedSubheadIds, subheadData?._id])
                                }
                            } else {
                                setSelectedSubheadIds([subheadData?._id])
                                setcopyDialogOpen(true)
                            }
                        }}
                    >
                        <FileCopyIcon />
                        {selectedSubheadIds.includes(subheadData?._id) ? (
                            <p>Remove</p>
                        ) : (
                            <p>Copy</p>
                        )}
                    </div>
                </div>}
            />
        </td> */}
    </tr>);
};

export default BudgetCostSubhead;
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { getAllBudgetCostByProfileId, getAllProjectBlocks } from "./api.call";
import CreateBudgetCost from "./CreateBudgetCost";
import BudgetCostCard from "./BudgetCostCard";
import emptyFile from "../../Assets/emptyData.svg"
import emptyPage from "../../Assets/EmptyPageSVG.svg"
import { getWalletDataByProfileId } from "../finance2o/accounts/api.call";
import { getProfileDataPopulate } from "../team/procurements/api.call";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createDefaultTemplateHook } from "../approval/approvalHelper";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    headerCont: {
        width: "100%",
        height: "60px",
        borderBottom: "1px solid #ebeaea",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        "& h3": {
            fontSize: "18px",
            fontWeight: "600"
        }
    },
    barLeft: {
        display: "flex",
        alignItems: "center"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        paddingRight: "20px",
        "& .MuiButton-label": {
            padding: "0px 1px",
            textTransform: "capitalize",
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100vh - 65px)",
        overflow: "hidden",
        display: "flex",
        flexWrap: "wrap",
        alignContent: "baseline"
    },
    cardsCont: {
        width: "33.33%",
        padding: "15px",
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
    },
    singleCard: {
        width: "33.33%",
        height: "150px",
        padding: "15px",
    },
    budgetCostCard: {
        width: "100%",
        height: "100%",
        borderRadius: "3px"
    },
    emptyLeftSideCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "30%",
            height: "auto",
        },
        "& div": {
            width: "100%",
            marginTop: "10px",
            padding: "0px 20px",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
            textAlign: "center",
        },
        "& a": {
            textDecoration: "none",
            fontSize: "14px",
            marginLeft: "5px",
            cursor: "pointer",
            color: "blue"
        }
    },
    emptyLibrarayCont: {
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "200px",
            height: "auto",
        },
        "& div": {
            width: "100%",
            marginTop: "10px",
            padding: "0px 20px",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
            textAlign: "center",
        },
        "& a": {
            textDecoration: "none",
            fontSize: "14px",
            marginLeft: "5px",
            cursor: "pointer",
            color: "blue"
        }
    },
}));

const AllBudgetCostCont = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { profileId } = useParams();

    const { teamDictionary, teamIds } = useSelector((state) => state.team);
    const { user } = useSelector((state) => state.auth);

    const bodyRef = useRef(null);

    const [budgetCosts, setBudgetCosts] = useState([])
    const [openBudgetCost, setOpenBudgetCost] = useState(false);
    const [projectId, setProjectId] = useState(null)
    const [projectBlocks, setProjectBlocks] = useState([])
    const [budgetCostObj, setBudgetCostObj] = useState(null)
    const [walletData, setWalletData] = useState(null)
    const [loader, setLoader] = useState(true)
    const [profileData, setProfileData] = useState(null)
    const [defaultCreateLoader, setDefaultCreateLoader] = useState(false)
    const [canCreateCost, setCanCreateCost] = useState(false)

    const getProjectData = async () => {
        await getProfileDataPopulate({ profileId })
            .then((data) => {
                console.log(data)
                setProfileData(data)
                if (data?.parent?.wallet?.defaultLibrary?.defaultEstimationApproval?.stages && data.parent.wallet.defaultLibrary.defaultEstimationApproval.stages.length > 0) {
                    const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultEstimationApproval?.stages[0];
                    const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                    if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                        setCanCreateCost(true)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (profileId) {
            getProjectData()
        }
    }, [profileId])

    const getBasicData = async () => {
        //get wallet data
        setLoader(true)
        await getWalletDataByProfileId({ profileId: profileId })
            .then(async (data1) => {
                setWalletData(data1)
                if (data1?.defaultLibrary) {
                    //get project blocks
                    let locProjectId = null;
                    teamIds && teamIds.length > 0 && teamIds.map((teamId) => {
                        const teamData = teamDictionary[teamId];
                        if (teamData?.parent?.profile?._id == profileId) {
                            locProjectId = teamData?.parent?._id
                        }
                        if (teamData?.parent?.profile == profileId) {
                            locProjectId = teamData?.parent?._id
                        }
                    })

                    setProjectId(locProjectId)
                    await getAllProjectBlocks({ projectId: locProjectId })
                        .then((data2) => {
                            setProjectBlocks(data2)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    //get budget costs
                    await getAllBudgetCostByProfileId({ profileId: profileId })
                        .then((data3) => {
                            setBudgetCosts(data3)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (profileId && profileId !== undefined && profileId !== null) {
            getBasicData()
        }
    }, [profileId])

    const addNewItemFun = (item, isnew) => {
        if (isnew) {
            let curBlock = null;
            projectBlocks.map((block) => {
                if (block?._id == item?.projectBlock) {
                    curBlock = block
                }
            })
            const obj = {
                ...item,
                projectBlock: curBlock
            }
            setBudgetCosts([...budgetCosts, obj])
        } else {
            let arr = []
            budgetCosts.map((budgetCost) => {
                if (budgetCost?._id === item?._id) {
                    arr.push(item)
                } else {
                    arr.push(budgetCost)
                }
            })
            setBudgetCosts(arr)
        }
    }

    const createDefaultsTemplates = async () => {
        setDefaultCreateLoader(true)
        const entity = profileData?.parent?._id;
        const entityModelName = profileData?.parentModelName;
        const entityParent = profileData?.parent?.ownerProfile?.parent;
        const entityParentModelName = profileData?.parent?.ownerProfile?.parentModelName;
        await createDefaultTemplateHook({
            profileId, 
            entity, 
            entityModelName, 
            entityParent, 
            userId: user?._id,
            entityParentModelName, 
            ownerProfileId: profileData?.parent?.ownerProfile?._id, 
            types: ["Estimation"],
            libraryId: profileData?.parent?.wallet?.defaultLibrary?._id
    })
            .then(async (data) => {
                console.log(data)
                await getProjectData();
                setDefaultCreateLoader(false)
            })
            .catch(async (error) => {
                console.log(error)
                await getProjectData();
                setDefaultCreateLoader(false)
            })
    }

    return (<>
        {!profileData?.parent?.wallet?.defaultLibrary?._id ? (
            <div className={classes.emptyLibrarayCont} >
                <img src={emptyPage} />
                <div>
                    <p>
                        No Library exists in the current directory or any library still didn't selected as default library.<br />
                        Click <a
                            onClick={() => {
                                history.push(`/offerings-categories/management/${profileId}`)
                            }}
                        >here</a> to create and manage your library(s), category(s), product(s) and service(s)
                    </p>
                </div>
            </div>
        ) : (<>{(!profileData?.parent?.wallet?.defaultLibrary?.defaultEstimationApproval) ? (
            <div className={classes.emptyLibrarayCont} >
                <img src={emptyPage} />
                <div>
                    <p>{defaultCreateLoader ? `Template Creating....` : (<>
                        Template is missing in the current directory or no template is selected as default.<br />
                        Click <a
                            onClick={createDefaultsTemplates}
                        >here</a> to create default template(s)
                    </>)}
                    </p>
                </div>
            </div>
        ) : (
            <div className={classes.root} >
                <div className={classes.headerCont} >
                    <div className={classes.barLeft} >
                        <IconButton className={classes.backArrow} onClick={() => { history.goBack() }} >
                            <KeyboardBackspaceIcon className={classes.backArrow} />
                        </IconButton>
                        <h3>Budget Management Dashboard</h3>
                    </div>
                    <div className={classes.barRight} >
                        {canCreateCost && (<>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={walletData?.defaultLibrary ? false : true}
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setBudgetCostObj({
                                        profile: profileId,
                                        user: user?._id,
                                        projectId: projectId,
                                        title: "",
                                        projectBlock: projectBlocks && projectBlocks.length > 0 ? projectBlocks[0] : null
                                    })
                                    setOpenBudgetCost(true)
                                }}
                            >
                                Add Budget Cost
                            </Button>
                            <CreateBudgetCost
                                openBudgetCost={openBudgetCost}
                                setOpenBudgetCost={setOpenBudgetCost}
                                projectBlocks={projectBlocks}
                                budgetCostObj={budgetCostObj}
                                addNewItemFun={addNewItemFun}
                                profileData={profileData}
                                projectId={projectId}
                            />
                        </>)}
                    </div>
                </div>
                <div className={classes.loaderCont} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.bodyCont} >
                    {budgetCosts && budgetCosts.length > 0 ? budgetCosts.map((budgetCost, i) => (
                        <div className={classes.cardsCont} >
                            <BudgetCostCard
                                key={i}
                                budgetCost={budgetCost}
                                setBudgetCostObj={setBudgetCostObj}
                                setOpenBudgetCost={setOpenBudgetCost}
                                projectId={projectId}
                                profileId={profileId}
                                projectBlocks={projectBlocks}
                            />
                        </div>
                    )) : (<div className={classes.emptyLeftSideCont} >
                        <img src={emptyFile} />
                        <div>
                            <p>No budget cost is available
                                <a
                                    onClick={() => {
                                        setBudgetCostObj({
                                            profile: profileId,
                                            user: user?._id,
                                            projectId: projectId,
                                            title: "",
                                            projectBlock: projectBlocks && projectBlocks.length > 0 ? projectBlocks[0] : null
                                        })
                                        setOpenBudgetCost(true)
                                    }}
                                >
                                    click here
                                </a> to add.</p>
                        </div>
                    </div>)}
                </div>
            </div>
        )}
        </>
        )}
    </>);
};

export default AllBudgetCostCont;
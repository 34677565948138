import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../../styled/CommonComponents/NormalDrawer";
import BudgetTasksListView from "./budgetTask/BudgetTasksListView";

const useStyles = makeStyles((theme) => ({
    bodyCont: {
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    }
}));

const LinkedTasksDetails = ({ openLinkedTasksDetails, setOpenLinkedTasksDetails, taskIds }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    return (
        <NormalDrawer
            openDrawer={openLinkedTasksDetails}
            setOpenDrawer={setOpenLinkedTasksDetails}
            anchor={"right"}
            width={"60vw"}
            title={"Linked Tasks Details"}
            content={<div className={classes.bodyCont} >
                <BudgetTasksListView taskIds={taskIds} />
            </div>}
        />
    );
};

export default LinkedTasksDetails;
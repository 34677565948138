import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import NormalDrawer from "../../styled/CommonComponents/NormalDrawer";
import moment from "moment";
import Skeleton from '@material-ui/lab/Skeleton';
import emptyfolder from "../../../Assets/FileIcon/emptyfolder.png"
import { getAllBudgetUpdateByGivenFilter } from "../api.call";
import config from "../../../config/index";
import DrawerTable from "./DrawerTable";

const useStyles = makeStyles((theme) => ({
    bodyCont: {
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    skeletonSty: {
        width: "100%",
        height: "35px",
        marginBottom: "5px"
    },
    emptyCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "14px",
            color: "gray",
            marginTop: "5px"
        },
        "& img": {
            width: "250px",
            height: "auto"
        }
    }
}));

const PaidDetails = ({
    openPaidDetails, setOpenPaidDetails, selectedSubhead,
    getBudgetCostPopulatedData, needToMakeDisable
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const [allBudgetUpdateds, setAllBudgetUpdateds] = useState([])
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        if (selectedSubhead?._id) {
            setLoader(true)
            getAllBudgetUpdateByGivenFilter({
                givenFilter: {
                    $and: [
                        { subworkhead: selectedSubhead?._id },
                        { status: "Paid" }
                    ]
                }
            })
                .then((data) => {
                    let locUpdateItem = []
                    var isDev = config?.mode == 'dev'
                    data.map((item) => {
                        locUpdateItem.push({
                            ...item,
                            title: `Transaction-${item?.tx?.txNo}`,
                            date: moment(item?.createdAt).format("DD/MM/YYYY"),
                            link: isDev?`${config.BASE_URL}finance/${item?.tx?.firstPartyWallet}/${item?.tx?.type === "Invoice" ? "invoice" : "bill"}/edit/${item?.tx?._id}`:
                            `https://accounts.reallist.ai/finance/${item?.tx?.firstPartyWallet}/${item?.tx?.type === "Invoice" ? "invoice" : "bill"}/edit/${item?.tx?._id}`,
                        })
                    })
                    setAllBudgetUpdateds(locUpdateItem)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [selectedSubhead?._id])

    return (
        <NormalDrawer
            openDrawer={openPaidDetails}
            setOpenDrawer={setOpenPaidDetails}
            anchor={"right"}
            width={"50vw"}
            title={"Paid Amount Details"}
            content={
                <div className={classes.bodyCont} >
                    {loader ? (<>
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                    </>) : (<>
                        {allBudgetUpdateds && allBudgetUpdateds.length > 0 ? allBudgetUpdateds.length > 0 && (
                            <DrawerTable
                                allBudgetUpdateds={allBudgetUpdateds}
                                setAllBudgetUpdateds={setAllBudgetUpdateds}
                                reloadData={getBudgetCostPopulatedData}
                                amountField={"paidAmount"}
                                needToMakeDisable={needToMakeDisable}
                            />
                        ) : (<div className={classes.emptyCont} >
                            <img src={emptyfolder} />
                            <p>No budget-update connected with current subhead</p>
                        </div>)}
                    </>)}
                </div>
            }
        />
    );
};

export default PaidDetails;
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import real2 from "../../Assets/real2.PNG";
import LessText from "../styled/CommonComponents/LessText";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  singleCard: {
    width: "100%",
    height: "150px",
    padding: "15px",
    cursor: "pointer",
    position: "relative",
  },
  budgetCostCard: {
    width: "100%",
    height: "100%",
    display: "flex",
    borderRadius: "3px",
    justifyContent: "space-between",
    padding: "10px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    "&:hover": {
      transform: "scale(1.01)",
    },
    "& img": {
      width: "65px",
      height: "auto",
    },
    "& h3": {
      fontSize: "18px",
      fontWeight: "600",
      wordBreak: "break-all",
    },
    "& h4": {
      fontSize: "13px",
      wordBreak: "break-all",
      fontWeight: "500",
      backgroundColor: "#ececec",
      padding: "0px 7px",
      margin: "3px 0px",
      borderRadius: "7px",
    },
    "& h5": {
      fontSize: "13px",
      color: "gray",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "11px",
      marginTop: "10px",
      fontWeight: "500",
    },
  },
  detailsCont: {
    width: "calc(100% - 80px)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  popOverCont: {
    position: "absolute",
    zIndex: "9",
    top: "17px",
    right: "17px",
  },
  optionCont: {
    padding: "5px",
    "& .MuiSvgIcon-root": {
      fontSize: "19px",
    },
  },
  singleOption: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    paddingRight: "20px",
    cursor: "pointer",
    "& p": {
      marginLeft: "5px",
    },
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
}));

const BudgetCostCard = ({
  budgetCost,
  setBudgetCostObj,
  setOpenBudgetCost,
  projectId,
  profileId,
  projectBlocks,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className={classes.singleCard}>
      <div
        className={classes.budgetCostCard}
        onClick={() => {
          history.push(`/budget-analysis/budget-cost/edit/${budgetCost?._id}`);
        }}
      >
        <img src={real2} />
        <div className={classes.detailsCont}>
          <h3>
            <LessText
              limit={25}
              string={budgetCost?.title || "Budget Cost Title"}
            />
          </h3>
          <h4>
            <LessText
              limit={44}
              string={budgetCost?.projectBlock?.name || "Project Block Title"}
            />
          </h4>
          <h5>
            {moment(budgetCost?.createdAt).format("MMM Do YYYY, hh:mm a")}
          </h5>
          <p>
            <span style={{ color: "black" }}>
              ₹ {numberWithCommas(budgetCost?.totalBudgetAmount || 0)}
            </span>{" "}
            /{" "}
            <span style={{ color: "blue" }}>
              ₹{numberWithCommas(budgetCost?.totalGrossAmount || 0)}
            </span>{" "}
            /{" "}
            <span style={{ color: "green" }}>
              ₹{numberWithCommas(budgetCost?.totalPaidAmount || 0)}
            </span>
          </p>
        </div>
      </div>
      <div className={classes.popOverCont}>
        <MyPopOver
          closeOnClick={true}
          appearContent={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }
          showContent={
            <div className={classes.optionCont}>
              <div
                className={classes.singleOption}
                onClick={() => {
                  setBudgetCostObj({
                    _id: budgetCost?._id,
                    profile: profileId,
                    user: user?._id,
                    projectId: projectId,
                    title: budgetCost?.title || "",
                    projectBlock: budgetCost?.projectBlock
                      ? budgetCost?.projectBlock
                      : projectBlocks && projectBlocks.length > 0
                      ? projectBlocks[0]
                      : null,
                  });
                  setOpenBudgetCost(true);
                }}
              >
                <CreateIcon />
                <p>Edit</p>
              </div>
              <div className={classes.singleOption}>
                <DeleteIcon />
                <p>Delete</p>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default BudgetCostCard;

import {
  Box, Grid, MenuItem, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import HorizBox from "../../../styled/generic/HorizBox";
import { useDispatch } from "react-redux";
import Api from "../../../../helpers/Api";
import LoadingBox from "../../../styled/generic/LoadingHandler";
import SearchField from "../../../styled/generic/SearchField";
import Select from "../../../styled/generic/Select";
import StandardAppContainerRoundedWithHeader from "../../../styled/generic/StandardAppContainerRoundedWithHeader";
import BudgetTaskCard from "./BudgetTaskCard";
import StandardAppContainer from "../../../styled/generic/StandardAppContainer";

const BudgetTasksListView = ({ taskIds }) => {
  const dispatch = useDispatch();
  const ORDER_OPTIONS = ["DATE CREATED", "PROGRESS", "START DATE"];

  const [searchStr, setSearchStr] = useState("");
  const [orderFilter, setOrderFilter] = useState("");
  const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTasksForListView = async () => {
    try {
      setLoading(true);
      const getRes = await Api.post("/wbs/get/by-taskIds", {
        taskIds: taskIds,
        searchStr: searchStr,
        orderFilter: orderFilter,
        orderFilterOrder: orderFilterOrder,
        onlyRootTasks: true,
        enablePagination: true,
      });

      if (getRes?.data) {
        setTasks(getRes?.data || []);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching tasks",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching tasks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTasksForListView();
  }, [searchStr, orderFilter, orderFilterOrder]);

  return (
      <Box p={"20px"} >
        <BoxSpaceBetween>
          <Box flex="0.5">
            <SearchField
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
              sx={{
                backgroundColor: "white",
                boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
              }}
              fullWidth={true}
              size="small"
            />
          </Box>
          <HorizBox>
            <Select
              value={orderFilter}
              onChange={(e) => setOrderFilter(e.target.value)}
              displayEmpty
              size="small"
            >
              <MenuItem value="">Filter (None)</MenuItem>
              {ORDER_OPTIONS.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
            <Select
              value={orderFilterOrder}
              onChange={(e) => setOrderFilterOrder(e.target.value)}
              displayEmpty
              size="small"
            >
              <MenuItem value="Ascending">Ascending</MenuItem>
              <MenuItem value="Descending">Descending</MenuItem>
            </Select>
          </HorizBox>
        </BoxSpaceBetween>
        <LoadingBox loading={loading} fullHeight={true}>
          <Box sx={{ mt: 2 }}>
            {tasks?.length <= 0 ? (
              <Box>
                <Typography variant="subtitle1" color="textSecondary">
                  No tasks found
                </Typography>
              </Box>
            ) : (
              <Box>
                <Grid container spacing={2}>
                  {tasks.map((task, index) => (
                    <Grid item xs={12} md={8} lg={6} spacing={2}>
                      <BudgetTaskCard
                        task={task}
                        setTasks={setTasks}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>
        </LoadingBox>
      </Box>
  );
};

export default BudgetTasksListView;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Button, TextField, ClickAwayListener } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import "./index.css"
import { useDebounce } from "react-use";
import { getOfferingsByLibraryAndSearchStr } from "../OfferingsAndProducts/Api.call";
import UnitAutocomplete from "../styled/CommonComponents/UnitAutocomplete";

const useStyles = makeStyles((theme) => ({
    numberInputSty: {
        border: "none",
        textAlign: "right"
    },
    textInputSty: {
        border: "none",
        fontSize: "15px",
        fontWeight: "500",
        paddingLeft: "5px"
    },
    tableErrMsg: {
        padding: "0px",
        margin: "0px",
        fontSize: "10px",
        fontWeight: "400",
        color: "red",
        height: "12px",
        textAlign: "left",
        paddingLeft: "7px"
    },
    OptionTabShow: {
        width: "100%",
        maxHeight: "150px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
        position: "absolute",
        zIndex: "999",
        top: "43px",
        overflowY: "auto",
        overflowX: "hidden",
        overflow: "hidden",
        left: "0px",
        transition: "all .3s ease-in-out",
    },
    OptionTabHide: {
        width: "100%",
        maxHeight: "0px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
        position: "absolute",
        zIndex: "999",
        overflowY: "auto",
        overflowX: "hidden",
        top: "43px",
        left: "0px",
        overflow: "hidden",
        transition: "all .3s ease-in-out",
    },
    tableSty: {
        width: "100%",
        border: "1px solid gray",
        borderCollapse: "collapse",
        tableLayout: "auto",
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "14px",
            fontWeight: "500",
            padding: "0px",
            textAlign: "right"
        }
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    }
}));

const WorkitemResourceInputTable = ({
    resourceData, changeResourceName, ItemDetailsObj, j, showErrors,
    changeResourceQty, changeResourceQtyWastage, changeResourceUnit,
    changeResourceRate, changeResourceTax, deleteResource, selectedLibrary,
    onSelectOffering
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const [nameInput, setNameInput] = useState("")
    const [openSearch, setOpenSearch] = useState(false)
    const [searchActive, setSearchActive] = useState(false)
    const [results, setResults] = useState([])
    const [searchLoader, setSearchLoader] = useState(false)

    useEffect(() => {
        setNameInput(resourceData?.name)
    }, [resourceData?.name])

    useDebounce(() => {
        if (nameInput && nameInput.length > 2 && searchActive) {
            setSearchLoader(true)
            getOfferingsByLibraryAndSearchStr({
                libraryId: selectedLibrary?._id,
                searchStr: nameInput
            })
                .then((data) => {
                    setResults(data)
                    setSearchLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setOpenSearch(false)
            setResults([])
        }
    }, 200, [nameInput, selectedLibrary?._id])


    return (
        <tr className="resourceLine" key={j} >
            <td>
                <ClickAwayListener
                    onClickAway={() => {
                        setOpenSearch(false);
                        setSearchActive(false);
                        setResults([]);
                        setSearchLoader(true)
                    }}
                >
                    <div style={{ width: "100%", paddingTop: "12px" }} onClick={() => { setSearchActive(true) }} >
                        <input
                            value={resourceData?.name}
                            onChange={(e) => {
                                if (e.target.value && e.target.value.length > 2) {
                                    setOpenSearch(true)
                                } else {
                                    setOpenSearch(false)
                                }
                                changeResourceName(ItemDetailsObj?.name, j, e.target.value)
                                setNameInput(e.target.value)
                            }}
                            type="text"
                            placeholder="Enter item name"
                            className={classes.textInputSty}
                            style={{ width: "100%" }}
                        />
                        <div className={classes.tableErrMsg} >
                            {resourceData?.name && resourceData.name.length > 1 ? null : showErrors ? "Name is required" : null}
                        </div>
                    </div>
                </ClickAwayListener>
            </td>
            <td>
                <input
                    value={resourceData?.qty}
                    onChange={(e) => {
                        changeResourceQty(ItemDetailsObj?.name, j, e.target.value)
                    }}
                    type="number"
                    placeholder="Enter quantity"
                    className={classes.numberInputSty}
                    style={{ width: "100%" }}
                />
            </td>
            <td>
                <input
                    value={resourceData?.qtyWastage}
                    onChange={(e) => {
                        changeResourceQtyWastage(ItemDetailsObj?.name, j, e.target.value)
                    }}
                    type="number"
                    placeholder="Enter quantity"
                    className={classes.numberInputSty}
                    style={{ width: "100%" }}
                />
            </td>
            <td style={{ paddingRight: "10px" }} >
                {parseFloat(resourceData?.totalQty || 0).toFixed(2)}
            </td>
            <td>
                <UnitAutocomplete
                    value={resourceData?.unit}
                    onChange={(e, value) => {
                        changeResourceUnit(ItemDetailsObj?.name, j, value);
                    }}
                    fullWidth={true}
                />
            </td>
            <td>
                <input
                    value={resourceData?.price}
                    onChange={(e) => {
                        changeResourceRate(ItemDetailsObj?.name, j, e.target.value);
                    }}
                    type="number"
                    placeholder="Enter rate"
                    className={classes.numberInputSty}
                    style={{ width: "100%" }}
                />
            </td>
            <td>
                <input
                    value={resourceData?.tax}
                    onChange={(e) => {
                        changeResourceTax(ItemDetailsObj?.name, j, e.target.value)
                    }}
                    type="number"
                    placeholder="Enter tax"
                    className={classes.numberInputSty}
                    style={{ width: "100%" }}
                />
            </td>
            <td style={{ paddingRight: "10px" }} >
                {parseFloat(resourceData?.rateIncludingTax || 0).toFixed(2)}
            </td>
            <td style={{ paddingRight: "10px" }} >
                <div className={classes.totalDeleteCont} >
                    <p>{parseFloat(resourceData?.totalCostIncludingTax || 0).toFixed(2)}</p>
                    <DeleteIcon onClick={() => { deleteResource(ItemDetailsObj?.name, j) }} className="resourceDeleteIcon" />
                </div>
            </td>
            <div className={openSearch ? classes.OptionTabShow : classes.OptionTabHide} >
                {searchLoader && results.length === 0 ? (
                    <div>
                        Loading...
                    </div>
                ) : (<>{results.length === 0 ? (
                    <div>
                        No result is available
                    </div>
                ) : (
                    <table className={classes.tableSty} >
                        <thead style={{ width: "100%" }} >
                            <tr style={{ visibility: "collapse" }} >
                                <th>Name</th>
                                <th style={{ width: "150px" }} >Unit</th>
                                <th style={{ width: "150px" }} >Rate</th>
                                <th style={{ width: "150px" }} >Tax</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((offering) => (
                                <tr
                                    className={classes.tableRow}
                                    onClick={() => {
                                        setOpenSearch(false)
                                        setSearchActive(false)
                                        onSelectOffering(ItemDetailsObj?.name, j, offering)
                                    }}
                                >
                                    <td style={{ textAlign: "left", marginLeft: "5px" }} >{offering?.name}</td>
                                    <td style={{ width: "150px", marginRight: "5px" }} >Unit - {offering?.purchaseUnit}</td>
                                    <td style={{ width: "150px", marginRight: "5px" }} >Rate - ₹{offering?.purchasePrice}</td>
                                    <td style={{ width: "150px", marginRight: "5px" }} >Tax - {offering?.tax}{offering?.taxType}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
                }</>)}
            </div>
        </tr>
    );
};

export default WorkitemResourceInputTable;